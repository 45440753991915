import {
    Button,
    IconButton,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import ImagePlaceholder from "../../../assets/images/image-placeholder-horizontal.png";
import TextFieldIcon from "../../../assets/images/benefits/text-field.png";
import DropdownIcon from "../../../assets/images/benefits/dropdown.png";
import CheckboxIcon from "../../../assets/images/benefits/checkbox.png";
import RadioButtonIcon from "../../../assets/images/benefits/radio-button.png";
import UploadFile from "../../../components/UploadFile/UploadFile";
import "../Create/style.scss";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import PreviewModal from "../../../components/PreviewModal/PreviewModal";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FIELDS, VALIDATIONS } from "./saleFields";
import { Editor } from "@tinymce/tinymce-react";
import { useStore } from "../../../store/StoreContext";
import { flowResult } from "mobx";
import NotificationModal from "../../../components/NotificationModal/NotificationModal";
import { useHistory, useParams } from "react-router-dom";
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import DeleteIcon from "../../../assets/icons/delete.svg";
import AddIcon from "@material-ui/icons/Add";
import Spinner from "../../../components/Spinner/Spinner";
import ProceedImage from "../../../shared/utils/proceedImage";
import { useTranslation } from "react-i18next";
import heLocale from "date-fns/locale/he";
import enLocale from "date-fns/locale/en-US";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../shared/utils/cropImage";
import { convertLocalToZuluTime, convertZuluToLocalTime, convertToDate, getElementFromArrayObject, formatAppearsIds } from "../../../shared/utils/sharedFunctions";
import { tinyEditorOptions } from "../../../shared/utils/sharedOptions";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import UserTypeEnum from "../../../models/UserType";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { compressAccurately } from "image-conversion";

const ViewSale = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [innerLoading, setInnerLoading] = useState(false);
    const params: any = useParams();
    const id = params.id;
    const areFieldsDisabled = params.type === "view";
    const { i18n, t } = useTranslation();
    const history = useHistory();
    const {
        benefitStore,
        actionStore,
        saleStore,
        languageStore,
        archiveStore,
        userStore,
        groupStore,
        departmentStore,
    } = useStore();
    const [isPushNotification, setIsPushNotification] = useState(false);
    const [header, setHeader] = useState("");
    const [description, setDescription] = useState("");
    const [textEditor, setTextEditor] = useState("");
    const [pageType, setPageType] = useState("");
    const [isRedirect, setIsRedirect] = useState(true);
    const [roleType, setRoleType] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [archiveDays, setArchiveDays] = useState(7);
    const resolutionIsOrBelow1440 = window.innerWidth <= 1440;
    const resolutionAbove1024 = window.innerWidth > 1024;
    const resolutionIs1024 = window.innerWidth === 1024;
    const getCropWidth = () => {
        if (!resolutionIsOrBelow1440) return 403;
        else if (resolutionIsOrBelow1440 && resolutionAbove1024) return 322.4;
        else if (resolutionIs1024) return 268.75;
        return 201.6;
    };
    const getCropHeight = () => {
        if (!resolutionIsOrBelow1440) return 268.6;
        else if (resolutionIsOrBelow1440 && resolutionAbove1024) return 214.9;
        else if (resolutionIs1024) return 179.1;
        return 134.4;
    };

    const [categories, setCategories] = useState([
        {
            id: "",
            categoryName: "",
            categoryType: "",
        },
    ]);

    const [groups, setGroups] = useState<any>([]);
    const [departments, setDepartments] = useState<any>([]);
    const [secondaryDepartments, setSecondaryDepartments] = useState<any>([]);
    const [isSelectAllSelected, setIsSelectAllSelected] = useState(true);
    const [isSelectAllSelectedSecondary, setIsSelectAllSelectedSecondary] = useState(true);

    const [fileUpload, setFileUpload] = useState({
        lastModified: 0,
        lastModifiedDate: new Date(),
        name: "",
        size: 0,
        type: "",
        webkitRelativePath: "",
        url: ImagePlaceholder,
    });
    const [imageOriginal, setImageOriginal] = useState("");

    // Preview Modal
    const [openModal, setOpenModal] = useState(false);
    // Notification Modal
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");

    const [formBuilder, setFormBuilder] = useState([
        {
            type: "placeholder",
            label: "",
            options: [
                {
                    key: "",
                    value: "",
                },
            ],
            is_required: false,
        },
    ]);

    const addForm = (type) => {
        let form = {};
        if (type === "textfield") {
            form = {
                type: type,
                label: "",
            };
        }
        if (type === "radio" || type === "dropdown" || type === "checkbox") {
            form = {
                type: type,
                label: "",
                options: [
                    {
                        key: 1,
                        value: "",
                    },
                    {
                        key: 2,
                        value: "",
                    },
                ],
            };
        }
        if (pageType === "SURVEY") {
            form['is_required'] = false;
        }
        const newForm = [...formBuilder];
        // @ts-ignore
        newForm.push(form);
        setFormBuilder(newForm);
        setValue("fields", newForm);
    };

    const addFormOption = (itemIndex) => {
        const newForm = formBuilder.map((form: any, index) => {
            if (itemIndex === index) {
                // key starts from 1, not 0, thats why key is length + 1
                return { ...form, options: [...form.options, { key: form.options.length + 1, value: "" }] };
            }
            return form;
        });
        setFormBuilder(newForm);
    };

    const deleteForm = (itemIndex) => {
        const newForm = formBuilder.filter((form, index) => {
            return itemIndex !== index;
        });
        setFormBuilder(newForm);
    };

    const deleteFormOption = (itemIndex, optionIndex) => {
        const newForm = formBuilder.map((form, index) => {
            if (itemIndex === index) {
                return {
                    ...form,
                    options: [
                        ...form.options.filter((opt, optIndex) => optIndex !== optionIndex),
                    ],
                };
            }
            return form;
        });
        setFormBuilder(newForm);
    };

    const isCharOfLabelIsNumber = (label) => {
        if (!label.length) return false;
        return label[0] <= '9' && label[0] >= '0'
    }

    const handleLabelChangeOnForm = (itemIndex, value) => {
        const newForm = formBuilder.map((form, index) => {
            if (itemIndex === index) {
                return { ...form, label: value };
            }
            return form;
        });
        setFormBuilder(newForm);
    };

    const handleOptionChangeOnForm = (
        itemIndex,
        itemType,
        optionIndex,
        value
    ) => {
        const newForm = formBuilder.map((form, index) => {
            if (itemIndex === index) {
                return {
                    ...form,
                    options: [
                        ...form.options.map((opt, optIndex) => {
                            if (optIndex === optionIndex) {
                                return { ...opt, [itemType]: value };
                            }
                            return opt;
                        }),
                    ],
                };
            }
            return form;
        });
        setFormBuilder(newForm);
    };

    const handleIsRequiredChangeOnForm = (itemIndex) => {
        const newForm = formBuilder.map((form, index) => {
            if (itemIndex === index) {
                return { ...form, is_required: !form.is_required };
            }
            return form;
        });
        setFormBuilder(newForm);
    };

    const [showImage, setShowImage] = useState(false);

    const calculateCropperSettings = (height, width) => {
        if (width > height * 1.488) {
            let zoom = (width / height * 0.67);
            zoom = zoom >= 1 ? zoom : zoom;
            setZoom(zoom);
            setMaxZoom(zoom * 4);
        } else {
            setZoom(1);
            setMaxZoom(4);
        }
        setTimeout(() => {
            const cropperImage: any = document.querySelector('.reactEasyCrop_Image');
            if (cropperImage.className.indexOf('reactEasyCrop_Cover_Vertical') > -1) {
                cropperImage.style.height = '100.1%';
            } else if (cropperImage.className.indexOf('reactEasyCrop_Cover_Horizontal') > -1) {
                cropperImage.style.width = '100.1%';
            }
        })
    }

    const handleFileUpload = async (data) => {
        if (data) {
            setInnerLoading(true);
            setShowImage(true);
            const image: any = await ProceedImage.toBase64(data);
            setFileUpload({ ...data, url: image });
            setValue("image", image);

            // Save original image
            let compressed: Blob;
            if (data.type.indexOf("image") !== -1) {
              // Maximum for firbase is 300kb
              compressed = await compressAccurately(data, {size: 280, accuracy: 0.99});
            }
            const formData = new FormData();
            formData.append("file", compressed || data);
            const imageToURL = await flowResult(actionStore.uploadFile(formData)).then(
              (response: any) => {
                setIsLoading(false);
                return response;
              }
            );
            setImageOriginal(imageToURL);

            //Initiate the FileReader object.
            const reader = new FileReader();
            //Read the contents of Image File.
            reader.onload = function (e) {
                //Initiate the JavaScript Image object.
                let image: any = new Image();

                //Set the Base64 string return from FileReader as source.
                image.src = e.target.result;

                //Validate the File Height and Width.
                image.onload = function () {
                    const height: number = Number(this.height);
                    const width: number = Number(this.width);
                    calculateCropperSettings(height, width);
                }
            };
            reader.readAsDataURL(data);
            setIsNewPictureUploaded(true);
            setInnerLoading(false);
        }
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        getValues,
        clearErrors,
    } = useForm({
        resolver: yupResolver(VALIDATIONS),
        mode: "onChange",
        defaultValues: {
            // Primary
            category_id: "",
            page_type: "",
            header: "",
            description: "",
            image: "",
            appears: [],
            from_date: convertToDate(new Date()),
            to_date: convertToDate(
                new Date(new Date().setDate(new Date().getDate() + 1))
            ),
            is_push_notification: false,
            push_notification_text: "",
            publishWhen: "now",
            publish_date: convertToDate(new Date()),
            archiveWhen: "globalDate",
            archive_date: convertToDate(
                new Date(new Date().setDate(new Date().getDate() + 1))
            ),
            // Leads
            date_from: convertToDate(new Date()),
            date_to: convertToDate(
                new Date(new Date().setDate(new Date().getDate() + 1))
            ),
            details: "write",
            external_link: "",
            // Leads - form builder
            full_name: "",
            email: "",
            phone_number: "",
            supplier_email: "",
            supplier_second_email: "",
            fields: [],

            // Link
            button_text: "",
            link: "",

            // Store
            date: null,
            hour: null,
            location: "",
            number_of_tickets: 0,
            max_purchases: 0,
            price: 0,

            // Used in Store & Leads
            details_header: "",
            details_text: "",
            is_draft: false,
        },
    } as any);

    const [submitError, setSubmitError] = useState("");

    useEffect(() => {
        Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
    }, [register]);

    const stringToCapital = (text) => {
        if (text && text.length > 1) {
            text = text.toLowerCase();
            return text?.charAt(0).toUpperCase() + text?.slice(1);
        }
        return text;
    };

    const returnTypeData = async (objectData) => {
        let data = {};
        let croppedImage = objectData.thumbnail || objectData.image;
        if (isNewPictureUploaded) {
            croppedImage = await cropImage(croppedAreaPixels);
        }
        const publishDate = objectData.publish === "now" ? convertLocalToZuluTime(new Date()) : convertLocalToZuluTime(publishDateAndTime);
        const archiveDate = objectData.archive === "globalDate" ? convertLocalToZuluTime(new Date(new Date().setDate(new Date().getDate() + archiveDays)))
            : convertLocalToZuluTime(archiveDateAndTime);
        data = {
            item_type: pageType,
            category_id: objectData.category_id,
            from_date: publishDate,
            to_date: archiveDate,
            header: objectData.header,
            description: objectData.description,
            image: croppedImage || objectData.image,
            image_original: imageOriginal ?? "",
            appears: objectData.appears.filter((el) => el !== 'select-all'),
            publish_date: publishDate,
            archive_date: archiveDate,
            is_push_notification: objectData.is_push_notification,
            push_notification_text: objectData.push_notification_text,
            is_draft: false,
        }
        if (pageType === "LEADS") {
            const emails = [objectData.supplier_email];
            if (objectData.supplier_second_email) {
                emails.push(objectData.supplier_second_email)
            }
            data = {
                ...data,
                details: {
                    supplier_email: [...emails],
                    date_from: objectData.date_from,
                    date_to: objectData.date_to,
                    details_header: objectData.header,
                    details_text: textEditor,
                    form_builder: {
                        fields: [...formBuilder].filter((el) => el.type !== "placeholder"),
                    },
                },
            };
        }
        if (pageType === "LINK") {
            data = {
                ...data,
                details: {
                    button_text: objectData.button_text,
                    link: objectData.link,
                },
            };
        }
        if (pageType === "STORE") {
            data = {
                ...data,
                details: {
                    date: storeDateAndTime,
                    hour: storeDateAndTime,
                    location: objectData.location,
                    number_of_tickets: objectData.number_of_tickets,
                    max_purchases: objectData.max_purchases,
                    price: objectData.price,
                    details_header: objectData.header,
                    details_text: textEditor,
                },
            };
        }
        if (pageType === "SURVEY") {
            const emails = [objectData.supplier_email];
            if (objectData.supplier_second_email) {
                emails.push(objectData.supplier_second_email)
            }
            data = {
                ...data,
                item_type: pageType,
                details: {
                    supplier_email: [...emails],
                    form_builder: {
                        fields: [...formBuilder].filter((el) => el.type !== "placeholder"),
                    },
                },
            };
        }
        return data;
    };

    const saveBenefit = async (data) => {
        setIsLoading(true);
        setSubmitError("");
        if (isNewPictureUploaded) {
            const formData = new FormData();
            formData.append("file", data.image);
            const croppedImage = await flowResult(actionStore.uploadFile(formData)).then(
                (response: any) => {
                    return response;
                }
            );
            data = { ...data, image: croppedImage }
        }
        await flowResult(saleStore.update(data, id)).then((response: any) => {
            setIsLoading(false);
            if (!response?.success) {
                handleNotificationOpen(
                    response?.code
                        ? t(`apiMessages.${response.code}`)
                        : t("apiMessages.0"),
                    false
                );
                return;
            }
            handleNotificationOpen(
                response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"),
                true
            );
        });
    };

    const handleSubmitCheck = async (objectData) => {
        if (formBuilder.filter(el => isCharOfLabelIsNumber(el.label)).length > 0) {
            return;
        }
        if (areFieldsDisabled) {
            setOpenModal(true);
            return;
        }
        if (publishInvalidDate || archiveInvalidDate) {
            return;
        }
        let data = await returnTypeData(objectData);
        const dataKeys = Object.keys(data);
        dataKeys.forEach((el) => {
            if (!data[el] && el !== 'is_draft') delete data[el];
            if (el === "details") {
                const detailsKeys = Object.keys(data[el]);
                detailsKeys.forEach((det) => {
                    // @ts-ignore
                    if (!data.details[det]) delete data.details[det];
                });
            }
        });
        setSubmitError("");
        saveBenefit(data);
    };

    const handleCloseModal = () => () => {
        setOpenModal(false);
    };

    const handleNotificationOpen = (message = "", isRedirect) => {
        setNotificationMessage(message);
        setIsRedirect(isRedirect);
        setOpenNotificationModal(true);
    };

    const handleNotificationClose = () => {
        setOpenNotificationModal(false);
        if (isRedirect) history.push("/benefits/list");
    };

    const transformEditorText = (text) => {
        if (text && text.length > 0) {
            return text
                .replace(/(<([^>]+)>)/gi, "")
                // eslint-disable-next-line no-useless-escape
                .replace(/\&nbsp;/g, "")
                .trim();
        }
        return text;
    };

    useEffect(() => {
        setCategories(benefitStore.getStoredBenefits());
        const groups = groupStore.getStoredGroups();
        const departments = departmentStore.getStoredDepartments();
        const secondaryDepartments = departmentStore.getStoredSecondaryDepartments();
        setGroups(groups);
        setDepartments(departments);
        setSecondaryDepartments(secondaryDepartments);
        const roleType = userStore.getRoleType();
        setRoleType(roleType);
        setArchiveDays(archiveStore.getBenefitArchiveDays());
        flowResult(actionStore.getPathItems(`benefit/${id}`))
            .then((response: any) => {
                if (!response?.success) {
                    handleNotificationOpen(
                        response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"),
                        true
                    );
                    setIsLoading(false);
                    return;
                }
                const benefit = response.benefit;
                let appears = formatAppearsIds(benefit?.appears, roleType);
                appears = appears.filter((el, index) => appears.indexOf(el) === index);
                const groupOrDepartment = roleType === UserTypeEnum.owner ? groups : [...departments, ...secondaryDepartments];
                if(roleType !== UserTypeEnum.owner) {
                    setIsSelectAllSelected(appears.filter(depID => departments.some(dep => dep.id === depID)).length === departments.length);
                    setIsSelectAllSelectedSecondary(appears.filter(depID => secondaryDepartments.some(dep => dep.id === depID)).length === secondaryDepartments.length);
                } else {
                    setIsSelectAllSelected(appears.length === groupOrDepartment.length);
                }
                setValue("appears", appears.length === groupOrDepartment.length ? [...appears, 'select-all'] : appears);

                setValue("category_id", benefit.categoryId);
                setValue("page_type", benefit.benefitType);
                setPageType(benefit.benefitType);
                setValue("header", benefit.header);
                setHeader(benefit.header);
                setValue("description", benefit?.description ?? "");
                setDescription(benefit?.description ?? "");
                setValue("image", benefit?.image ?? ImagePlaceholder);
                setImageOriginal(benefit?.imageOriginal ?? "");
                setFileUpload({
                    ...fileUpload,
                    url: benefit?.image ?? ImagePlaceholder,
                });
                setShowImage(!!benefit?.image);
                setValue("is_push_notification", benefit?.isPushNotification ?? false);
                setIsPushNotification(benefit?.isPushNotification ?? false);
                setValue("push_notification_text", benefit?.pushNotificationText ?? "");
                const publishDate = new Date(convertZuluToLocalTime(benefit.publishDate));
                const archiveDate = new Date(convertZuluToLocalTime(benefit.archiveDate));
                setValue("publish_date", convertToDate(publishDate));
                setPublishDateAndTime(publishDate);
                setPublishHourAndMinutes({
                    hour: publishDate.getHours(),
                    minutes: publishDate.getMinutes(),
                });
                setValue("archive_date", convertToDate(archiveDate));
                setArchiveDateAndTime(archiveDate);
                setArchiveHourAndMinutes({
                    hour: archiveDate.getHours(),
                    minutes: archiveDate.getMinutes(),
                });
                // validateDateBefore(publishDate, setPublishInvalidDate);
                // validateDateBefore(archiveDate, setArchiveInvalidDate, "archive");
                // Leads
                setValue(
                    "date_from",
                    convertToDate(
                        benefit.details?.date_from
                            ? new Date(benefit.details.date_from)
                            : new Date()
                    )
                );
                setValue(
                    "date_to",
                    convertToDate(
                        benefit.details?.date_to
                            ? new Date(benefit.details.date_to)
                            : new Date()
                    )
                );
                // Leads - form builder
                setValue("supplier_email", benefit.details?.supplier_email?.[0] ?? "");
                setValue("supplier_second_email", benefit.details?.supplier_email?.[1] ?? "");
                setValue("fields", benefit.details?.form_builder?.fields ?? []);
                setFormBuilder(benefit.details?.form_builder?.fields ?? []);

                // Link
                setValue("button_text", benefit.details?.button_text ?? "");
                setValue("link", benefit.details?.link ?? "");

                // Store
                if (benefit.details?.date) {
                    const detailsDate = new Date(benefit.details.date);
                    setStoreDateAndTime(detailsDate);
                    setStoreHourAndMinutes({
                        hour: detailsDate.getHours(),
                        minutes: detailsDate.getMinutes(),
                    });
                    setValue(
                        "date",
                        convertToDate(detailsDate)
                    );
                    setValue(
                        "hour",
                        convertToDate(detailsDate)
                    );
                }
                setValue("location", benefit.details?.location ?? "");
                setValue("number_of_tickets", benefit.details?.number_of_tickets ?? 0);
                setValue("max_purchases", benefit.details?.max_purchases ?? 0);
                setValue("price", benefit.details?.price ?? 0);

                // Used in Store & Leads
                // setValue("details_header", benefit.details?.details_header ?? "");
                setValue(
                    "details_text",
                    transformEditorText(benefit.details?.details_text) ?? ""
                );
                setTextEditor(benefit.details?.details_text ?? "");
                setTimeout(() => {
                    setIsLoading(false);
                })
            })
    }, []);

    // Detecting Language Switch
    const [lngDirection, setLngDirection] = useState("");

    useEffect(() => {
        languageStore.switchLng = false;
        setIsLoading(true);
        const dir = i18n.dir();
        setLngDirection(dir);
        if (!firstLoad) {
            setTimeout(() => {
                setIsLoading(false)
            })
        }
        setFirstLoad(false);
    }, [languageStore.switchLng]);

    const textInputDirection = () => {
        if (lngDirection === "rtl") return "rtl";
        if (lngDirection === "ltr") return "ltr";
    };

    // Ref

    const categoryRef: any = useRef(null);
    const pageTypeRef: any = useRef(null);
    const headerRef: any = useRef(null);
    const descriptionRef: any = useRef(null);
    const imageRef: any = useRef(null);
    const appearsRef: any = useRef(null);
    const pushTextRef: any = useRef(null);
    const fieldsRef: any = useRef(null);
    const buttonRef: any = useRef(null);
    const linkRef: any = useRef(null);
    const locationRef: any = useRef(null);
    const ticketsRef: any = useRef(null);
    const maxPurchasesRef: any = useRef(null);
    const priceRef: any = useRef(null);
    const detailsHeaderRef: any = useRef(null);
    const detailsTextRef: any = useRef(null);
    const detailsEmailRef: any = useRef(null);
    const publishDateRef: any = useRef(null);
    const archiveDateRef: any = useRef(null);

    const scrollToError = () => {
        if (errors?.category_id?.message && categoryRef?.current) {
            categoryRef.current.scrollIntoView();
        } else if (errors?.page_type?.message && pageTypeRef?.current) {
            pageTypeRef.current.scrollIntoView();
        } else if (errors?.header?.message && headerRef?.current) {
            headerRef.current.scrollIntoView();
        } else if (errors?.description?.message && descriptionRef?.current) {
            descriptionRef.current.scrollIntoView();
        } else if (errors?.image?.message && imageRef?.current && !isNewPictureUploaded && !fileUpload.url) {
            imageRef.current.scrollIntoView();
            // @ts-ignore
        } else if (errors?.appears?.message && appearsRef?.current) {
            appearsRef.current.scrollIntoView();
        } else if (
            errors?.push_notification_text?.message &&
            pushTextRef?.current
        ) {
            pushTextRef.current.scrollIntoView();
        } else if ((errors?.supplier_email?.message || errors?.supplier_second_email?.message) && detailsEmailRef?.current) {
            detailsEmailRef.current.scrollIntoView();
            // @ts-ignore
        } else if (errors?.fields?.message) {
            fieldsRef.current.scrollIntoView();
        } else if (errors?.button_text?.message && buttonRef?.current) {
            buttonRef.current.scrollIntoView();
        } else if (errors?.link?.message && linkRef?.current) {
            linkRef.current.scrollIntoView();
        } else if (errors?.location?.message && locationRef?.current) {
            locationRef.current.scrollIntoView();
        } else if (errors?.number_of_tickets?.message && ticketsRef?.current) {
            ticketsRef.current.scrollIntoView();
        } else if (errors?.max_purchases?.message && maxPurchasesRef?.current) {
            maxPurchasesRef.current.scrollIntoView();
        } else if (errors?.price?.message && priceRef?.current) {
            priceRef.current.scrollIntoView();
        } else if (errors?.details_text?.message && detailsTextRef?.current) {
            detailsTextRef.current.scrollIntoView();
        } else if (publishInvalidDate) {
            publishDateRef.current.scrollIntoView();
        } else if (archiveInvalidDate) {
            archiveDateRef.current.scrollIntoView();
        }
        const element = document.querySelector('.main-content-container');
        if (element) {
            element.scrollTo(0, 0);
        }
    };

    const clearFieldsPerType = (type) => {
        if (type === pageType) {
            return;
        }
        // Leads
        setValue("date_from", convertToDate(new Date()));
        setValue(
            "date_to",
            convertToDate(new Date(new Date().setDate(new Date().getDate() + 1)))
        );
        setValue("details", "write");
        setValue("external_link", "");
        // Leads - form builder
        setValue("supplier_email", "");
        setValue("supplier_second_email", "");
        setValue("fields", []);
        setFormBuilder([]);

        // Link
        setValue("button_text", "");
        setValue("link", "");

        // Store
        setValue("date", null);
        setValue("hour", null);
        setValue("location", "");
        setValue("number_of_tickets", 0);
        setValue("max_purchases", 0);
        setValue("price", 0);

        // Used in Store & Leads
        setValue("details_header", "");
        setValue("details_text", "");
        setTextEditor("");
        clearErrors();
    };

    const [publishDateAndTime, setPublishDateAndTime] = useState(new Date());
    const [publishHourAndMinutes, setPublishHourAndMinutes] = useState({
        hour: 0,
        minutes: 0,
    });

    const [archiveDateAndTime, setArchiveDateAndTime] = useState(new Date());
    const [archiveHourAndMinutes, setArchiveHourAndMinutes] = useState({
        hour: 0,
        minutes: 0,
    });

    const [storeDateAndTime, setStoreDateAndTime] = useState(null);
    const [storeHourAndMinutes, setStoreHourAndMinutes] = useState({
        hour: 0,
        minutes: 0,
    });

    const changeDateOnly = (
        time,
        hourAndMinutes,
        setDateAndTime,
        setInvalidDate?,
        type = ""
    ) => {
        if (time instanceof Date) {
            const hours = hourAndMinutes.hour;
            const minutes = hourAndMinutes.minutes;
            if (!isNaN(hours) && !isNaN(minutes))
                setDateAndTime((date: any) => {
                    if (date) {
                        date = new Date(time);
                        date.setHours(hours, minutes);
                        const newDate = new Date(date);
                        return newDate;
                    }
                    return new Date();
                });
        } else {
            setDateAndTime(null)
        }
    };

    const changeTimeOnly = (
        time,
        setDateAndTime,
        setHourAndMinutes,
        setInvalidDate?,
        type = ""
    ) => {
        if (time instanceof Date) {
            if (!isNaN(time.getHours()) && !isNaN(time.getMinutes()))
                setDateAndTime((date: any) => {
                    if (date) {
                        date.setHours(time.getHours(), time.getMinutes());
                        const newDate = new Date(date);
                        setHourAndMinutes({
                            hour: time.getHours(),
                            minutes: time.getMinutes(),
                        });
                        return newDate;
                    }
                    return new Date();
                });
            
        } else {
            setDateAndTime(null)
        }
    };

    const [publishInvalidDate, setPublishInvalidDate] = useState(false);
    const [archiveInvalidDate, setArchiveInvalidDate] = useState(false);


    // Cropper
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [maxZoom, setMaxZoom] = useState(4);
    const [isNewPictureUploaded, setIsNewPictureUploaded] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState({
        height: 0,
        width: 0,
        x: 0,
        y: 0,
    });

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const cropImage = async (croppedAreaPixels) => {
        const croppedImage = await getCroppedImg(
            fileUpload?.url,
            croppedAreaPixels,
            true
        );
        return croppedImage;
    };

    const handleShowOriginal = () => {
        const img = new Image();
        img.onload = function (e: any) {
            const imgInformation = e?.path?.[0] ?? {};
            if (Object.keys(imgInformation)) {
                calculateCropperSettings(imgInformation.height, imgInformation.width);
            }
        };
        img.src = imageOriginal;
        setIsNewPictureUploaded(true);
        setFileUpload({ ...fileUpload, url: imageOriginal });
        setValue("image", imageOriginal);
    }

    const onDepartmentSelectChange = (data, isSelectAll = false) => {
        const secondaries = getValues('appears').filter(depID => secondaryDepartments.some(dep => dep.id === depID))
        setValue('appears', [...data, ...secondaries]);
        setIsSelectAllSelected(isSelectAll)
      }
    const onSecondaryDepartmentSelectChange = (data, isSelectAll = false) => {
        const deps = getValues('appears').filter(depID => departments.some(dep => dep.id === depID))
        setValue('appears', [...data, ...deps]);
        setIsSelectAllSelectedSecondary(isSelectAll);
      }

    return isLoading ? (
        <Spinner />
    ) : (
        <div className="create-benefit">
            <form
                className=""
                autoComplete="off"
                onSubmit={handleSubmit((d) => handleSubmitCheck(d))}
            >
                <div className="main-wrapper benefit-page">
                    <div className="create-options">
                        <h2 className="page-heading">
                            {areFieldsDisabled
                                ? t("benefits.view.title")
                                : t("benefits.edit.title")}
                        </h2>
                        <div className="main-wrapper">
                            <p>{t("benefits.create.form.title")}</p>
                            <Grid container className="create-grid">
                                <Grid item xs={4} lg={3} ref={categoryRef}>
                                    <FormControl>
                                        <InputLabel
                                            id="category-label"
                                            className={`${areFieldsDisabled ? "text-disabled" : ""}`}
                                        >
                                            {t("common.fieldHeaders.category")}
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name="category_id"
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    labelId="category-label"
                                                    id="category"
                                                    displayEmpty
                                                    value={value}
                                                    onChange={onChange}
                                                    defaultValue=""
                                                    disabled={areFieldsDisabled}
                                                >
                                                    {categories.map((cat, index) => (
                                                        <MenuItem key={index} value={cat.id}>
                                                            {cat.categoryName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                    {!areFieldsDisabled && (
                                        <p className="field-error">
                                            {errors?.category_id?.message ?? ""}&nbsp;
                                        </p>
                                    )}
                                </Grid>
                                <div className="separator" />
                                <Grid item xs={4} lg={3} ref={pageTypeRef}>
                                    <FormControl>
                                        <InputLabel
                                            id="item_type-label"
                                            className={`${areFieldsDisabled ? "text-disabled" : ""}`}
                                        >
                                            {t("common.fieldHeaders.pageType")}
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name="page_type"
                                            render={({ field: { onChange, value } }) => (
                                                <>
                                                    <Select
                                                        labelId="item_type-label"
                                                        id="item_type"
                                                        displayEmpty
                                                        onChange={(e: any) => {
                                                            const type = e.target.value;
                                                            setPageType(type);
                                                            clearFieldsPerType(type);
                                                            onChange(e);
                                                        }}
                                                        value={value}
                                                        disabled={areFieldsDisabled}
                                                    >
                                                        <MenuItem value="LEADS">
                                                            {t("common.selectFilters.leads")}
                                                        </MenuItem>
                                                        <MenuItem value="LINK">
                                                            {t("common.selectFilters.link")}
                                                        </MenuItem>
                                                        <MenuItem value="STORE">
                                                            {t("common.selectFilters.store")}
                                                        </MenuItem>
                                                        <MenuItem value="SURVEY">
                                                            {t("common.selectFilters.survey")}
                                                        </MenuItem>
                                                    </Select>
                                                </>
                                            )}
                                        />
                                    </FormControl>
                                    {!areFieldsDisabled && (
                                        <p className="field-error">
                                            {errors?.page_type?.message ?? ""}&nbsp;
                                        </p>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div className="create-preview">
                        <div className="main-wrapper">
                            <h3>{t('common.buttons.preview')}</h3>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={lngDirection === "rtl" ? heLocale : enLocale}
                            >
                                <Grid container className="create-grid" justifyContent="space-between">
                                    <Grid item xs={8} lg={6}>
                                        <Grid container direction="column">
                                            <Grid item className="grid-row" xs={9} ref={headerRef}>
                                                <Controller
                                                    control={control}
                                                    name="header"
                                                    render={({ field: { onChange, value } }) => (
                                                        <>
                                                            <TextField
                                                                id="header"
                                                                label={t("common.fieldHeaders.header")}
                                                                value={value}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    onChange(e);
                                                                    setHeader(value);
                                                                    setValue('header', value);
                                                                }}
                                                                disabled={areFieldsDisabled}
                                                            />
                                                            {!areFieldsDisabled && (
                                                                <p className="field-error">
                                                                    {errors?.header?.message ?? ""}&nbsp;
                                                                </p>
                                                            )}
                                                        </>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                className="grid-row"
                                                xs={9}
                                                ref={descriptionRef}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="description"
                                                    render={({ field: { onChange, value } }) => (
                                                        <>
                                                            <TextField
                                                                id="description"
                                                                label={t("common.fieldHeaders.description")}
                                                                value={value}
                                                                onChange={(e) => {
                                                                    setDescription(e.target.value);
                                                                    onChange(e);
                                                                }}
                                                                disabled={areFieldsDisabled}
                                                            />
                                                            {!areFieldsDisabled && (
                                                                <p className="field-error">
                                                                    {errors?.description?.message ?? ""}&nbsp;
                                                                </p>
                                                            )}
                                                        </>
                                                    )}
                                                />
                                            </Grid>
                                            {!areFieldsDisabled ? (
                                                <Grid item className="grid-row" xs={9} ref={imageRef}>
                                                    <div className="show-original-image-button">
                                                        <Button
                                                            variant="contained"
                                                            name="ShowOriginal"
                                                            color="primary"
                                                            onClick={handleShowOriginal}
                                                            disabled={isNewPictureUploaded || !imageOriginal}
                                                        >
                                                            {t('common.buttons.showOriginal')}
                                                        </Button>
                                                    </div>
                                                    <UploadFile
                                                        text={t("common.fieldHeaders.uploadImage")}
                                                        handleFileUpload={handleFileUpload}
                                                        showImage={showImage}
                                                        image={fileUpload.url}
                                                        height={115}
                                                        width={175}
                                                    />
                                                    <span className="information-message">{t("informationMessages.imageOnly")}</span>
                                                    {!areFieldsDisabled && (
                                                        <p className="field-error">
                                                            {!showImage ? errors?.image?.message ?? "" : ""}
                                                            &nbsp;
                                                        </p>
                                                    )}
                                                </Grid>
                                            ) : (
                                                <></>
                                            )}
                                            <Grid item className="grid-row" xs={9} ref={appearsRef}>
                                                <FormControl>
                                                    <Controller
                                                        control={control}
                                                        name="appears"
                                                        render={({ field: { onChange, value } }) => (
                                                            <>
                                                                <label
                                                                    className={`field-label ${areFieldsDisabled ? "text-disabled" : ""
                                                                        }`}
                                                                >
                                                                    {t("common.fieldHeaders.appears")}
                                                                </label>
                                                                {roleType !== UserTypeEnum.owner && <><br />
                                  <label className={`field-label ${areFieldsDisabled ? "text-disabled" : ""
                                    }`}>{t('common.fieldHeaders.department')}</label></>}
                                                                <MultiSelect
                                                                    showSelectAll
                                                                    value={roleType === UserTypeEnum.owner ? value : value.filter(depID => departments.some(dep => dep.id === depID))}
                                                                    hasError={!!errors?.appears?.message}
                                                                    isSelectAllSelected={isSelectAllSelected}
                                                                    mapBy="id"
                                                                    renderedName="name"
                                                                    onChange={onDepartmentSelectChange}
                                                                    selectOptions={roleType === UserTypeEnum.owner ? groups : departments}
                                                                    isDisabled={
                                                                        areFieldsDisabled ||
                                                                        (roleType === UserTypeEnum.owner &&
                                                                            !groups.length) ||
                                                                        (roleType !== UserTypeEnum.owner &&
                                                                            !departments.length)
                                                                    }
                                                                />
                                                                {!areFieldsDisabled && (
                                                                    <>
                                                                        {(roleType === UserTypeEnum.owner &&
                                                                            groups.length) ||
                                                                            (roleType !== UserTypeEnum.owner &&
                                                                                departments.length) ? (
                                                                            <>
                                                                                <p className="field-error">
                                                                                    {" "}
                                                                                    {/* @ts-ignore */}
                                                                                    {errors?.appears?.message ??
                                                                                        ""}&nbsp;{" "}
                                                                                </p>
                                                                            </>
                                                                        ) : (
                                                                            <p className="field-error">
                                                                                {roleType === UserTypeEnum.owner
                                                                                    ? t(
                                                                                        "errorMessages.articles.create.noGroups"
                                                                                    )
                                                                                    : t(
                                                                                        "errorMessages.articles.create.noDepartments"
                                                                                    )}
                                                                            </p>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            {roleType !== UserTypeEnum.owner && secondaryDepartments.length > 0 && <Grid item className="grid-row" xs={9}>
                                                <FormControl>
                                                    <Controller
                                                        control={control}
                                                        name="appears"
                                                        render={({ field: { onChange, value } }) => (
                                                            <>
                                                            <label className={`field-label ${areFieldsDisabled ? "text-disabled" : ""
                                                                }`}>{t('common.fieldHeaders.secondaryDepartment')}</label>
                                                                <MultiSelect
                                                                    showSelectAll
                                                                    value={value.filter(depID => secondaryDepartments.some(dep => dep.id === depID))}
                                                                    isSelectAllSelected={isSelectAllSelectedSecondary}
                                                                    mapBy="id"
                                                                    renderedName="name"
                                                                    onChange={onSecondaryDepartmentSelectChange}
                                                                    selectOptions={secondaryDepartments}
                                                                    isDisabled={
                                                                        areFieldsDisabled ||
                                                                        (roleType !== UserTypeEnum.owner &&
                                                                            !secondaryDepartments.length)
                                                                    }
                                                                />
                                                            </>
                                                                
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>}
                                            <Grid item className="grid-row" xs={9} ref={pushTextRef}>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            control={control}
                                                            name="is_push_notification"
                                                            render={({ field: { onChange, value } }) => (
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={value || false}
                                                                    onChange={onChange}
                                                                    onClick={() => {
                                                                        setIsPushNotification(!value);
                                                                        if (value) {
                                                                            setValue("push_notification_text", "");
                                                                        }
                                                                    }}
                                                                    disabled={areFieldsDisabled}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={t("common.fieldHeaders.sendPushNotification")}
                                                    onClick={() =>
                                                        getValues("push_notification_text")
                                                            ? setValue("push_notification_text", "")
                                                            : null
                                                    }
                                                    disabled={areFieldsDisabled}
                                                />
                                                <Controller
                                                    control={control}
                                                    name="push_notification_text"
                                                    render={({ field: { onChange, value } }) => (
                                                        <>
                                                            <TextField
                                                                id="pushNotificationText"
                                                                type="text"
                                                                label={t(
                                                                    "common.fieldHeaders.pushNotificationText"
                                                                )}
                                                                disabled={
                                                                    areFieldsDisabled ||
                                                                    (!areFieldsDisabled && !isPushNotification)
                                                                }
                                                                value={value}
                                                                onChange={onChange}
                                                            />
                                                            {!areFieldsDisabled && (
                                                                <p className="field-error">
                                                                    {errors?.push_notification_text?.message ??
                                                                        ""}
                                                                    &nbsp;
                                                                </p>
                                                            )}
                                                        </>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item className="grid-row" xs={7}>
                                                <p
                                                    className={`${areFieldsDisabled ? "text-disabled" : ""
                                                        }`}
                                                >
                                                    {t("common.fieldHeaders.whenToPublishBenefit")}
                                                </p>
                                                <Grid container justifyContent="space-between" ref={publishDateRef}>
                                                    <Grid item xs={7}>
                                                        <KeyboardDatePicker
                                                            // {... !areFieldsDisabled ? { disablePast: true } : {}}
                                                            minDateMessage={t('errorMessages.minimumDate')}
                                                            disableToolbar
                                                            variant="inline"
                                                            format="dd/MM/yyyy"
                                                            margin="normal"
                                                            id="publishDate-picker-inline"
                                                            className="date-picker-inline"
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date",
                                                            }}
                                                            onChange={(value: any) => {
                                                                changeDateOnly(
                                                                    value,
                                                                    publishHourAndMinutes,
                                                                    setPublishDateAndTime,
                                                                    setPublishInvalidDate,
                                                                    "publish"
                                                                );
                                                            }}
                                                            value={convertToDate(publishDateAndTime)}
                                                            autoOk={true}
                                                            disabled={areFieldsDisabled}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <KeyboardTimePicker
                                                            margin="normal"
                                                            id="time-picker"
                                                            okLabel={t("timePicker.confirm")}
                                                            cancelLabel={t("timePicker.cancel")}
                                                            value={publishDateAndTime}
                                                            onChange={(value: any) => {
                                                                changeTimeOnly(
                                                                    value,
                                                                    setPublishDateAndTime,
                                                                    setPublishHourAndMinutes,
                                                                    setPublishInvalidDate,
                                                                    "publish"
                                                                );
                                                            }}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "Hour",
                                                            }}
                                                            disabled={areFieldsDisabled}
                                                        />
                                                    </Grid>
                                                    {!areFieldsDisabled ? (
                                                        <p className="field-error">
                                                            {publishInvalidDate ? t("push.errors.time") : ""}
                                                            &nbsp;
                                                        </p>
                                                    ) : null}
                                                </Grid>
                                            </Grid>
                                            <Grid item className="grid-row" xs={9}>
                                                <Grid container justifyContent="space-between">
                                                    <Grid item className="grid-row" xs={9}>
                                                        <p
                                                            className={`${areFieldsDisabled ? "text-disabled" : ""
                                                                }`}
                                                        >
                                                            {t("common.fieldHeaders.whenToArchiveBenefit")}
                                                        </p>
                                                        <Grid container justifyContent="space-between" ref={archiveDateRef}>
                                                            <Grid item xs={7}>
                                                                <KeyboardDatePicker
                                                                    // {... !areFieldsDisabled ? { minDate: publishDateAndTime } : {}}
                                                                    minDateMessage={t('errorMessages.minimumDate')}
                                                                    disableToolbar
                                                                    variant="inline"
                                                                    format="dd/MM/yyyy"
                                                                    margin="normal"
                                                                    id="archiveDate-picker-inline"
                                                                    className="date-picker-inline"
                                                                    KeyboardButtonProps={{
                                                                        "aria-label": "change date",
                                                                    }}
                                                                    onChange={(value: any) => {
                                                                        changeDateOnly(
                                                                            value,
                                                                            archiveHourAndMinutes,
                                                                            setArchiveDateAndTime,
                                                                            setArchiveInvalidDate,
                                                                            "archive"
                                                                        );
                                                                    }}
                                                                    value={convertToDate(archiveDateAndTime)}
                                                                    autoOk={true}
                                                                    disabled={areFieldsDisabled}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <KeyboardTimePicker
                                                                    margin="normal"
                                                                    id="time-picker"
                                                                    okLabel={t("timePicker.confirm")}
                                                                    cancelLabel={t("timePicker.cancel")}
                                                                    value={archiveDateAndTime}
                                                                    onChange={(value: any) => {
                                                                        changeTimeOnly(
                                                                            value,
                                                                            setArchiveDateAndTime,
                                                                            setArchiveHourAndMinutes,
                                                                            setArchiveInvalidDate,
                                                                            "archive"
                                                                        );
                                                                    }}
                                                                    KeyboardButtonProps={{
                                                                        "aria-label": "Hour",
                                                                    }}
                                                                    disabled={areFieldsDisabled}
                                                                />
                                                            </Grid>
                                                            {!areFieldsDisabled ? (
                                                                <p className="field-error">
                                                                    {archiveInvalidDate
                                                                        ? t("push.errors.time")
                                                                        : ""}
                                                                    &nbsp;
                                                                </p>
                                                            ) : null}
                                                        </Grid>
                                                    </Grid>
                                                    {!areFieldsDisabled ? (
                                                        <p className="field-error">
                                                            {archiveInvalidDate ? t("push.errors.time") : ""}
                                                            &nbsp;
                                                        </p>
                                                    ) : null}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        className="preview-container"
                                        style={{ zIndex: 9 }}
                                    >
                                        <div className="fixed">
                                            <div className={`preview-image`}
                                                style={{
                                                    backgroundImage:
                                                        fileUpload?.url && !isNewPictureUploaded
                                                            ? "url(" + fileUpload?.url + ")"
                                                            : " ",
                                                }}
                                            >
                                                {!areFieldsDisabled && isNewPictureUploaded ? (
                                                    <div className="crop-wrapper">
                                                        <Cropper
                                                            image={fileUpload?.url}
                                                            crop={crop}
                                                            zoom={zoom}
                                                            zoomSpeed={0.1}
                                                            minZoom={0.05}
                                                            maxZoom={maxZoom}
                                                            restrictPosition={false}
                                                            objectFit="horizontal-cover"
                                                            cropSize={{
                                                                width: getCropWidth(),
                                                                height: getCropHeight(),
                                                            }}
                                                            onCropChange={setCrop}
                                                            onZoomChange={setZoom}
                                                            onCropComplete={onCropComplete}
                                                        />
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="preview-text-container">
                                                <div className="preview-text">
                                                    <div>
                                                        {
                                                            (pageType === "STORE" || pageType === "SURVEY") && (
                                                                <div className="preview-category">
                                                                    {getValues('category_id') ? getElementFromArrayObject('id', 'categoryName', getValues('category_id'), categories) : t('common.fieldHeaders.category')}
                                                                </div>
                                                            )
                                                        }
                                                        <div className="preview-text-header">
                                                            <span>
                                                                {(header?.length > 40 ? (header.substring(0, 37) + "...") : header) || t("common.fieldHeaders.header")}
                                                            </span>
                                                        </div>
                                                        {
                                                            (pageType === "LEADS" || pageType === "LINK") && (
                                                                <div>
                                                                    <span className="preview-description">
                                                                        {(description?.length > 40 ? (description.substring(0, 37) + "...") : description) ||
                                                                            t("common.fieldHeaders.description")}
                                                                    </span>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            pageType === "STORE" && (
                                                                <>
                                                                    {
                                                                        storeDateAndTime && (
                                                                            <div className="d-flex align-center">
                                                                                <AccessTimeIcon className="time-icon" />
                                                                                <div className="d-flex align-center store-date-time">
                                                                                    <span className="store-date">{convertToDate(storeDateAndTime, "preview", "DD/MM")}</span>
                                                                                    <span className="separator">|</span>
                                                                                    <span className="store-time">{convertToDate(storeDateAndTime, "preview", "HH:MM")}</span>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    <div className="store-price">{t('common.currency') + getValues('price')}</div>
                                                                    {
                                                                        getValues('location') && (
                                                                            <div><LocationOnOutlinedIcon /> {getValues('location')}</div>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                    {
                                                        (publishDateAndTime && archiveDateAndTime && (pageType === "LINK")) ? (
                                                            <div className="d-flex date-text dates">
                                                                <span>
                                                                    {convertToDate(
                                                                        publishDateAndTime,
                                                                        "preview"
                                                                    ) || t("common.fieldHeaders.dateFrom")}
                                                                </span>
                                                                <div>&nbsp; - &nbsp;</div>
                                                                <span>
                                                                    {convertToDate(archiveDateAndTime, "preview") ||
                                                                        t("common.fieldHeaders.dateTo")}
                                                                </span>
                                                            </div>
                                                        ) : null
                                                    }
                                                    {
                                                        pageType === "LINK" && (
                                                            <div className="w-100">
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className="w-100"
                                                                    size="small"
                                                                >
                                                                    {getValues('button_text') ? getValues('button_text') : t('common.buttons.buttonText')}
                                                                </Button>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="proportion-container">
                                                <span className="text">{t('common.proportions', { x: 1.5, y: 1 })}</span>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </div>
                        {(pageType === "LEADS" || pageType === "SURVEY") && (
                            <>
                                {
                                    pageType === "LEADS" && (
                                        <div className="main-wrapper">
                                            <h3>{t("benefits.create.form.details")}</h3>
                                            <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                                locale={lngDirection === "rtl" ? heLocale : enLocale}
                                            >
                                                <Grid
                                                    container
                                                    className="create-grid"
                                                    justifyContent="space-between"
                                                    direction="column"
                                                >
                                                    <Grid item className="grid-row" xs={6} lg={4}>
                                                        <Grid container>
                                                            <Grid item className="grid-row" xs={6}>
                                                                <Controller
                                                                    control={control}
                                                                    name="date_from"
                                                                    render={(props) => (
                                                                        <KeyboardDatePicker
                                                                            label={t("common.fieldHeaders.dateFrom")}
                                                                            disableToolbar
                                                                            variant="inline"
                                                                            format="dd/MM/yyyy"
                                                                            margin="normal"
                                                                            id="detailsFromDate-picker-inline"
                                                                            className="date-picker-inline"
                                                                            KeyboardButtonProps={{
                                                                                "aria-label": "change date",
                                                                            }}
                                                                            autoOk={true}
                                                                            onChange={(value) => {
                                                                                props.field.onChange(convertToDate(value));
                                                                            }}
                                                                            value={props.field.value}
                                                                            disabled={areFieldsDisabled}
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item className="grid-row" xs={6}>
                                                                <Controller
                                                                    control={control}
                                                                    name="date_to"
                                                                    render={(props) => (
                                                                        <KeyboardDatePicker
                                                                            label={t("common.fieldHeaders.dateTo")}
                                                                            disableToolbar
                                                                            variant="inline"
                                                                            format="dd/MM/yyyy"
                                                                            margin="normal"
                                                                            id="detailsToDate-picker-inline"
                                                                            className="date-picker-inline"
                                                                            autoOk={true}
                                                                            onChange={(value) => {
                                                                                props.field.onChange(convertToDate(value));
                                                                            }}
                                                                            value={props.field.value}
                                                                            disabled={areFieldsDisabled}
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        className="grid-row"
                                                        xs={6} lg={4}
                                                        ref={detailsHeaderRef}
                                                    >
                                                        <Controller
                                                            control={control}
                                                            name="header"
                                                            render={({ field: { onChange, value } }) => (
                                                                <>
                                                                    <TextField
                                                                        id="header"
                                                                        type="text"
                                                                        label={t("common.fieldHeaders.header")}
                                                                        value={value}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            onChange(e);
                                                                            setHeader(value);
                                                                            setValue('header', value);
                                                                        }}
                                                                        disabled={areFieldsDisabled}
                                                                    />
                                                                    {!areFieldsDisabled && (
                                                                        <p className="field-error">
                                                                            {errors?.header?.message ?? ""}
                                                                            &nbsp;
                                                                        </p>
                                                                    )}
                                                                </>
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        className="grid-row"
                                                        xs={12}
                                                        ref={detailsTextRef}
                                                    >
                                                        <p
                                                            className={areFieldsDisabled
                                                                ? "text-disabled"
                                                                : ""
                                                            }
                                                        >
                                                            {t(
                                                                "articles.allArticles.create.form.preview.detailsText"
                                                            )}
                                                            {!areFieldsDisabled ? "*" : ""}
                                                        </p>
                                                        <Controller
                                                            control={control}
                                                            name="details_text"
                                                            render={({ field: { onChange, value } }) => (
                                                                <>
                                                                    <Editor
                                                                        apiKey='7c1lea82c6ixszj0qqq2wtdp01kgm8syyi4eq3r85hprx9mh'
                                                                        value={textEditor}
                                                                        init={{
                                                                            directionality: textInputDirection(),
                                                                            height: 500,
                                                                            width: "65%",
                                                                            zIndex: 1,
                                                                            ...tinyEditorOptions,
                                                                            toolbar_mode: 'sliding',
                                                                            content_style: `body > * { ${areFieldsDisabled ? "color: #8f9bb3;" : ""
                                                                                } ${lngDirection === "rtl"
                                                                                    ? "text-align: right;"
                                                                                    : "text-align: left;"
                                                                                }
                                                                        } body { overflow-x: hidden; }`,
                                                                        }}
                                                                        onEditorChange={(value) => {
                                                                            setTextEditor(value);
                                                                            onChange(transformEditorText(value));
                                                                        }}
                                                                        disabled={areFieldsDisabled}
                                                                    />
                                                                    {!areFieldsDisabled && (
                                                                        <p className="field-error">
                                                                            {errors?.details_text?.message ?? ""}
                                                                            &nbsp;
                                                                        </p>
                                                                    )}
                                                                </>
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    )
                                }
                                <div className="main-wrapper">
                                    <h3>{t("benefits.create.enterLabel")}</h3>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                        locale={lngDirection === "rtl" ? heLocale : enLocale}
                                    >
                                        <Grid
                                            container
                                            className="create-grid"
                                            justifyContent="space-between"
                                            direction="column"
                                            ref={fieldsRef}
                                        >
                                            <Grid item className={`${areFieldsDisabled ? 'grid-row' : ''}`} xs={6} lg={4} ref={detailsEmailRef}>
                                                <p>{t("benefits.create.form.detailsEmailTitle")}</p>
                                                <Controller
                                                    control={control}
                                                    name="supplier_email"
                                                    render={({ field: { onChange, value } }) => (
                                                        <>
                                                            <TextField
                                                                id="supplier_email"
                                                                type="text"
                                                                label={t("benefits.create.form.detailsEmail")}
                                                                value={value}
                                                                onChange={onChange}
                                                                disabled={areFieldsDisabled}
                                                            />
                                                            {
                                                                !areFieldsDisabled ? (
                                                                    <p className="field-error">
                                                                        {errors?.supplier_email?.message ?? ""}
                                                                        &nbsp;
                                                                    </p>
                                                                ) : null
                                                            }
                                                        </>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={6} lg={4}>
                                                <Controller
                                                    control={control}
                                                    name="supplier_second_email"
                                                    render={({ field: { onChange, value } }) => (
                                                        <>
                                                            <TextField
                                                                id="supplier_second_email"
                                                                type="text"
                                                                label={t("benefits.create.form.detailsEmail")}
                                                                value={value}
                                                                onChange={onChange}
                                                                disabled={areFieldsDisabled}
                                                            />
                                                            {
                                                                !areFieldsDisabled ? (
                                                                    <p className="field-error">
                                                                        {errors?.supplier_second_email?.message ?? ""}
                                                                        &nbsp;
                                                                    </p>
                                                                ) : null
                                                            }
                                                        </>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item className="grid-row" xs={10} lg={12}>
                                                <p
                                                    className={`${areFieldsDisabled ? "text-disabled" : ""
                                                        }`}
                                                >
                                                    {t(
                                                        "benefits.create.form.formBuilder.selectFieldType"
                                                    )}
                                                </p>
                                                <Grid container className="text-center">
                                                    <Grid
                                                        item
                                                        className={`grid-row box ${areFieldsDisabled ? "disabled" : ""
                                                            }`}
                                                        xs={2} lg={1}
                                                        onClick={() =>
                                                            !areFieldsDisabled ? addForm("textfield") : null
                                                        }
                                                    >
                                                        <img src={TextFieldIcon} alt="text-field" />
                                                        <div>
                                                            {t("benefits.create.form.formBuilder.textfield")}
                                                        </div>
                                                    </Grid>
                                                    {
                                                        pageType === "LEADS" && (
                                                            <Grid
                                                                item
                                                                className={`grid-row box ${areFieldsDisabled ? "disabled" : ""
                                                                    }`}
                                                                xs={2} lg={1}
                                                                onClick={() =>
                                                                    !areFieldsDisabled ? addForm("dropdown") : null
                                                                }
                                                            >
                                                                <img src={DropdownIcon} alt="dropdown" />
                                                                <div>
                                                                    {t("benefits.create.form.formBuilder.dropdown")}
                                                                </div>
                                                            </Grid>
                                                        )
                                                    }
                                                    <Grid
                                                        item
                                                        className={`grid-row box ${areFieldsDisabled ? "disabled" : ""
                                                            }`}
                                                        xs={2} lg={1}
                                                        onClick={() =>
                                                            !areFieldsDisabled ? addForm("checkbox") : null
                                                        }
                                                    >
                                                        <img src={CheckboxIcon} alt="checkbox" />
                                                        <div>
                                                            {t("benefits.create.form.formBuilder.checkbox")}
                                                        </div>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        className={`grid-row box ${areFieldsDisabled ? "disabled" : ""
                                                            }`}
                                                        xs={2} lg={1}
                                                        onClick={() =>
                                                            !areFieldsDisabled ? addForm("radio") : null
                                                        }
                                                    >
                                                        <img src={RadioButtonIcon} alt="radio-button" />
                                                        <div>
                                                            {t(
                                                                "benefits.create.form.formBuilder.radio"
                                                            )}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                {
                                                    !areFieldsDisabled && (
                                                        <p className="field-error">
                                                            {" "}
                                                            {errors?.fields?.message ?? ""}&nbsp;{" "}
                                                        </p>
                                                    )
                                                }
                                                <Grid item className="grid-row" xs={6} lg={4}>
                                                    {formBuilder.map((el, formIndex) => {
                                                        if (el.type === "textfield") {
                                                            return (
                                                                <Grid
                                                                    key={formIndex}
                                                                    container
                                                                    className="container-box"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        {t(`benefits.create.form.formBuilder.${el.type}`)}
                                                                    </Grid>
                                                                    <Grid item xs={11}>
                                                                        <TextField
                                                                            // label={el.label}
                                                                            placeholder={t(
                                                                                "benefits.create.enterLabel"
                                                                            )}
                                                                            value={el.label}
                                                                            onChange={(e) =>
                                                                                handleLabelChangeOnForm(
                                                                                    formIndex,
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                            InputLabelProps={{ shrink: true }}
                                                                            disabled={areFieldsDisabled}
                                                                        />
                                                                        {!areFieldsDisabled && (
                                                                            <p className="field-error">
                                                                                {!el.label.length
                                                                                    ? t(
                                                                                        "benefits.validationMessages.fillLabel"
                                                                                    )
                                                                                    : (isCharOfLabelIsNumber(el.label)
                                                                                        ? t(
                                                                                            "benefits.validationMessages.labelCantHaveNumberAsFirstChar"
                                                                                        )
                                                                                        : "")}
                                                                                &nbsp;
                                                                            </p>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        {!areFieldsDisabled ? (
                                                                            <IconButton
                                                                                aria-label="delete selected item"
                                                                                className="hover-item"
                                                                                onClick={() => deleteForm(formIndex)}
                                                                            >
                                                                                <IconComponent icon={DeleteIcon} />
                                                                            </IconButton>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </Grid>
                                                                    {
                                                                        pageType === "SURVEY" && (
                                                                            <Grid item xs={12}>
                                                                                <FormControlLabel
                                                                                    className="form-control-label"
                                                                                    control={
                                                                                        <Checkbox
                                                                                            color="primary"
                                                                                            checked={el.is_required || false}
                                                                                            onClick={() =>
                                                                                                handleIsRequiredChangeOnForm(formIndex)
                                                                                            }
                                                                                        />
                                                                                    }
                                                                                    label={t("benefits.create.form.formBuilder.isRequired")}
                                                                                />
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                </Grid>
                                                            );
                                                        }
                                                        if (
                                                            el.type === "radio"
                                                            || el.type === "dropdown"
                                                            || el.type === "checkbox"
                                                        ) {
                                                            return (
                                                                <Grid
                                                                    key={formIndex}
                                                                    container
                                                                    className="container-box"
                                                                    justifyContent="space-between"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        {t(`benefits.create.form.formBuilder.${el.type}`)}
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={11}
                                                                        className="container-box-item"
                                                                    >
                                                                        <TextField
                                                                            // label={el.label}
                                                                            placeholder={t(
                                                                                "benefits.create.enterLabel"
                                                                            )}
                                                                            value={el.label}
                                                                            onChange={(e) =>
                                                                                handleLabelChangeOnForm(
                                                                                    formIndex,
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                            InputLabelProps={{ shrink: true }}
                                                                            disabled={areFieldsDisabled}
                                                                        />
                                                                        {!areFieldsDisabled && (
                                                                            <p className="field-error">
                                                                                {!el.label.length
                                                                                    ? t(
                                                                                        "benefits.validationMessages.fillLabel"
                                                                                    )
                                                                                    : (isCharOfLabelIsNumber(el.label)
                                                                                        ? t(
                                                                                            "benefits.validationMessages.labelCantHaveNumberAsFirstChar"
                                                                                        )
                                                                                        : "")}
                                                                                &nbsp;
                                                                            </p>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        {!areFieldsDisabled ? (
                                                                            <IconButton
                                                                                aria-label="delete selected item"
                                                                                className="hover-item"
                                                                                onClick={() => deleteForm(formIndex)}
                                                                            >
                                                                                <IconComponent icon={DeleteIcon} />
                                                                            </IconButton>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </Grid>
                                                                    {
                                                                        pageType === "SURVEY" && (
                                                                            <Grid item xs={12}>
                                                                                <FormControlLabel
                                                                                    className="form-control-label"
                                                                                    control={
                                                                                        <Checkbox
                                                                                            color="primary"
                                                                                            checked={el.is_required || false}
                                                                                            onClick={() =>
                                                                                                handleIsRequiredChangeOnForm(formIndex)
                                                                                            }
                                                                                        />
                                                                                    }
                                                                                    label={t("benefits.create.form.formBuilder.isRequired")}
                                                                                />
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                    {el.options.map((option, optIndex) => {
                                                                        return (
                                                                            <Grid
                                                                                container
                                                                                key={optIndex}
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={6}
                                                                                    className="container-box-item"
                                                                                >
                                                                                    <TextField
                                                                                        label={t(
                                                                                            "benefits.create.form.formBuilder.value"
                                                                                        )}
                                                                                        placeholder={t(
                                                                                            "benefits.create.form.formBuilder.enterValue"
                                                                                        )}
                                                                                        value={option.value}
                                                                                        onChange={(e) =>
                                                                                            handleOptionChangeOnForm(
                                                                                                formIndex,
                                                                                                "value",
                                                                                                optIndex,
                                                                                                e.target.value
                                                                                            )
                                                                                        }
                                                                                        InputLabelProps={{ shrink: true }}
                                                                                        disabled={areFieldsDisabled}
                                                                                    />
                                                                                    {!areFieldsDisabled && (
                                                                                        <p className="field-error">
                                                                                            {!option.value
                                                                                                ? t(
                                                                                                    "benefits.validationMessages.fillValue"
                                                                                                )
                                                                                                : ""}
                                                                                            &nbsp;
                                                                                        </p>
                                                                                    )}
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    {!areFieldsDisabled ? (
                                                                                        <IconButton
                                                                                            aria-label="delete selected item"
                                                                                            className="hover-item"
                                                                                            onClick={() =>
                                                                                                deleteFormOption(
                                                                                                    formIndex,
                                                                                                    optIndex
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <IconComponent
                                                                                                icon={DeleteIcon}
                                                                                            />
                                                                                        </IconButton>
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}
                                                                                </Grid>
                                                                            </Grid>
                                                                        );
                                                                    })}
                                                                    {!areFieldsDisabled ? (
                                                                        <Grid
                                                                            item
                                                                            xs={6} lg={4}
                                                                            className="add-option-button"
                                                                            onClick={() => addFormOption(formIndex)}
                                                                        >
                                                                            <AddIcon />
                                                                            <span>
                                                                                {t(
                                                                                    "benefits.create.form.formBuilder.addOption"
                                                                                )}
                                                                            </span>
                                                                        </Grid>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </Grid>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </>
                        )}
                        {pageType === "LINK" && (
                            <div className="main-wrapper">
                                <h3>{t("benefits.create.form.details")}</h3>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={lngDirection === "rtl" ? heLocale : enLocale}
                                >
                                    <Grid
                                        container
                                        className="create-grid"
                                        justifyContent="space-between"
                                        direction="column"
                                    >
                                        <p
                                            className={`${areFieldsDisabled ? "text-disabled" : ""}`}
                                        >
                                            {t("benefits.create.form.addButton")}
                                        </p>
                                        <Grid item xs={6} lg={4}>
                                            <Grid container>
                                                <Grid item xs={3} ref={buttonRef}>
                                                    <Controller
                                                        control={control}
                                                        name="button_text"
                                                        render={({ field: { onChange, value } }) => (
                                                            <>
                                                                <TextField
                                                                    id="button_text"
                                                                    label={t("benefits.create.form.buttonText")}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    disabled={areFieldsDisabled}
                                                                />
                                                                {!areFieldsDisabled && (
                                                                    <p className="field-error">
                                                                        {errors?.button_text?.message ?? ""}&nbsp;
                                                                    </p>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Grid>
                                                <div className="small-separator" />
                                                <Grid item xs={8} ref={linkRef}>
                                                    <Controller
                                                        control={control}
                                                        name="link"
                                                        render={({ field: { onChange, value } }) => (
                                                            <>
                                                                <TextField
                                                                    id="link"
                                                                    label={t("benefits.create.form.link")}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    disabled={areFieldsDisabled}
                                                                />
                                                                {!areFieldsDisabled && (
                                                                    <p className="field-error">
                                                                        {errors?.link?.message ?? ""}&nbsp;
                                                                    </p>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        )}
                        {pageType === "STORE" && (
                            <div className="main-wrapper">
                                <h3>{t("benefits.create.form.details")}</h3>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={lngDirection === "rtl" ? heLocale : enLocale}
                                >
                                    <Grid
                                        container
                                        className="create-grid"
                                        justifyContent="space-between"
                                        direction="column"
                                    >
                                        <Grid item className="grid-row" xs={6} lg={4}>
                                            <Grid container>
                                                <Grid item className="grid-row" xs={8}>
                                                    <KeyboardDatePicker
                                                        // {... !areFieldsDisabled ? { minDate: publishDateAndTime } : {}}
                                                        minDateMessage={t('errorMessages.minimumDate')}
                                                        disableToolbar
                                                        variant="inline"
                                                        format="dd/MM/yyyy"
                                                        margin="normal"
                                                        id="storeDate-picker-inline"
                                                        className="date-picker-inline"
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date",
                                                        }}
                                                        onChange={(value: any) => {
                                                            changeDateOnly(
                                                                value,
                                                                storeHourAndMinutes,
                                                                setStoreDateAndTime
                                                            );
                                                        }}
                                                        value={convertToDate(storeDateAndTime)}
                                                        autoOk={true}
                                                        disabled={areFieldsDisabled}
                                                    />
                                                </Grid>
                                                <Grid item className="grid-row" xs={6} lg={4}>
                                                    <KeyboardTimePicker
                                                        margin="normal"
                                                        id="time-picker"
                                                        okLabel={t("timePicker.confirm")}
                                                        cancelLabel={t("timePicker.cancel")}
                                                        value={storeDateAndTime}
                                                        onChange={(value: any) => {
                                                            changeTimeOnly(
                                                                value,
                                                                setStoreDateAndTime,
                                                                setStoreHourAndMinutes
                                                            );
                                                        }}
                                                        KeyboardButtonProps={{
                                                            "aria-label": "Hour",
                                                        }}
                                                        disabled={areFieldsDisabled}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className="grid-row" xs={6} lg={4} ref={locationRef}>
                                            <Controller
                                                control={control}
                                                name="location"
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <TextField
                                                            id="location"
                                                            label={t("common.fieldHeaders.location")}
                                                            value={value}
                                                            onChange={onChange}
                                                            disabled={areFieldsDisabled}
                                                        />
                                                        {!areFieldsDisabled && (
                                                            <p className="field-error">
                                                                {errors?.location?.message ?? ""}&nbsp;
                                                            </p>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item className="grid-row" xs={4} ref={ticketsRef}>
                                            <Grid container justifyContent="space-between">
                                                <Grid item className="grid-row" xs={4}>
                                                    <Controller
                                                        control={control}
                                                        name="number_of_tickets"
                                                        render={({ field: { onChange, value } }) => (
                                                            <>
                                                                <TextField
                                                                    id="number_of_tickets"
                                                                    className="number_of_tickets"
                                                                    type="number"
                                                                    label={t(
                                                                        "common.fieldHeaders.numberOfTickets"
                                                                    )}
                                                                    value={value}
                                                                    onChange={(e: any) => {
                                                                        if (e.target.value) {
                                                                            onChange(e);
                                                                        } else {
                                                                            onChange(0);
                                                                        }
                                                                    }}
                                                                    disabled={areFieldsDisabled}
                                                                />
                                                                {!areFieldsDisabled && (
                                                                    <p className="field-error">
                                                                        {errors?.number_of_tickets?.message ?? ""}
                                                                        &nbsp;
                                                                    </p>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item className="grid-row" xs={4} ref={priceRef}>
                                                    <Controller
                                                        control={control}
                                                        name="price"
                                                        render={({ field: { onChange, value } }) => (
                                                            <>
                                                                <TextField
                                                                    id="price"
                                                                    className="number_of_tickets"
                                                                    type="number"
                                                                    label={t("common.fieldHeaders.price")}
                                                                    value={value}
                                                                    onChange={(e: any) => {
                                                                        if (e.target.value) {
                                                                            onChange(e);
                                                                        } else {
                                                                            onChange(0);
                                                                        }
                                                                    }}
                                                                    disabled={areFieldsDisabled}
                                                                />
                                                                {!areFieldsDisabled && (
                                                                    <p className="field-error">
                                                                        {errors?.price?.message ?? ""}&nbsp;
                                                                    </p>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item className="grid-row" xs={4}>
                                                    <Controller
                                                        control={control}
                                                        name="max_purchases"
                                                        render={({ field: { onChange, value } }) => (
                                                            <>
                                                                <TextField
                                                                    id="max_purchases"
                                                                    className="number_of_tickets"
                                                                    type="number"
                                                                    label={t(
                                                                        "common.fieldHeaders.maxPurchases"
                                                                    )}
                                                                    value={value}
                                                                    onChange={(e: any) => {
                                                                        if (e.target.value) {
                                                                            onChange(e);
                                                                        } else {
                                                                            onChange(0);
                                                                        }
                                                                    }}
                                                                    disabled={areFieldsDisabled}
                                                                />
                                                                {!areFieldsDisabled && (
                                                                    <p className="field-error">
                                                                        {errors?.max_purchases?.message ?? ""}
                                                                        &nbsp;
                                                                    </p>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            className="grid-row"
                                            xs={6} lg={4}
                                            ref={detailsHeaderRef}
                                        >
                                            <Controller
                                                control={control}
                                                name="header"
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <TextField
                                                            id="header"
                                                            label={t("common.fieldHeaders.header")}
                                                            value={header}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                setHeader(e.target.value);
                                                            }}
                                                            disabled={areFieldsDisabled}
                                                        />
                                                        {!areFieldsDisabled && (
                                                            <p className="field-error">
                                                                {errors?.header?.message ?? ""}&nbsp;
                                                            </p>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            className="grid-row"
                                            xs={12}
                                            ref={detailsTextRef}
                                        >
                                            <p
                                                className={`${areFieldsDisabled ? "text-disabled" : ""
                                                    }`}
                                            >
                                                {t(
                                                    "articles.allArticles.create.form.preview.detailsText"
                                                )}
                                                {!areFieldsDisabled ? "*" : ""}
                                            </p>
                                            <Controller
                                                control={control}
                                                name="details_text"
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <Editor
                                                            apiKey='7c1lea82c6ixszj0qqq2wtdp01kgm8syyi4eq3r85hprx9mh'
                                                            value={textEditor}
                                                            init={{
                                                                directionality: textInputDirection(),
                                                                height: 500,
                                                                width: "65%",
                                                                ...tinyEditorOptions,
                                                                toolbar_mode: 'sliding',
                                                                content_style: `body > * { ${areFieldsDisabled ? "color: #8f9bb3;" : ""
                                                                    } ${lngDirection === "rtl"
                                                                        ? "text-align: right;"
                                                                        : "text-align: left;"
                                                                    }
                                                                    } body { overflow-x: hidden; }`,
                                                            }}
                                                            onEditorChange={(value) => {
                                                                setTextEditor(value);
                                                                onChange(transformEditorText(value));
                                                            }}
                                                            disabled={areFieldsDisabled}
                                                        />
                                                        {!areFieldsDisabled && (
                                                            <p className="field-error">
                                                                {errors?.details_text?.message ?? ""}&nbsp;
                                                            </p>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        )}
                        <Grid item xs={12}>
                            <FormHelperText error className="error-text">
                                {submitError ? stringToCapital(submitError) : ""}&nbsp;
                            </FormHelperText>
                        </Grid>
                        <div className="submit-buttons">
                            <Button
                                variant="contained"
                                name="PreviewAndPublish"
                                color="primary"
                                type="submit"
                                onClick={() => setTimeout(() => scrollToError())}
                            >
                                {areFieldsDisabled
                                    ? t("common.buttons.preview")
                                    : t("common.buttons.save")}
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
            <PreviewModal
                className="create-benefit"
                openModal={openModal}
                handleClose={handleCloseModal}
                hideNotYet={true}
                content={
                    <>
                        <div className="preview-image-container">
                            <div className={`preview-image`}>
                                <img
                                    src={fileUpload?.url}
                                    alt={fileUpload?.name ?? ""}
                                />
                            </div>
                            <div className="preview-text-container">
                                <div className="preview-text">
                                    <div>
                                        {
                                            (pageType === "STORE" || pageType === "SURVEY") && (
                                                <div className="preview-category">
                                                    {getValues('category_id') ? getElementFromArrayObject('id', 'categoryName', getValues('category_id'), categories) : t('common.fieldHeaders.category')}
                                                </div>
                                            )
                                        }
                                        <div className="preview-text-header">
                                            <span>
                                                {(header?.length > 40 ? (header.substring(0, 37) + "...") : header) || t("common.fieldHeaders.header")}
                                            </span>
                                        </div>
                                        {
                                            (pageType === "LEADS" || pageType === "LINK") && (
                                                <div>
                                                    <span className="preview-description">
                                                        {(description?.length > 40 ? (description.substring(0, 37) + "...") : description) ||
                                                            t("common.fieldHeaders.description")}
                                                    </span>
                                                </div>
                                            )
                                        }
                                        {
                                            pageType === "STORE" && (
                                                <>
                                                    {
                                                        storeDateAndTime && (
                                                            <div className="d-flex align-center">
                                                                <AccessTimeIcon className="time-icon" />
                                                                <div className="d-flex align-center store-date-time">
                                                                    <span className="store-date">{convertToDate(storeDateAndTime, "preview", "DD/MM")}</span>
                                                                    <span className="separator">|</span>
                                                                    <span className="store-time">{convertToDate(storeDateAndTime, "preview", "HH:MM")}</span>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    <div className="store-price">{t('common.currency') + getValues('price')}</div>
                                                    {
                                                        getValues('location') && (
                                                            <div className="d-flex align-center">
                                                                <span className="location-icon"><LocationOnOutlinedIcon /></span>
                                                                <span>{getValues('location')}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </div>
                                    {
                                        (publishDateAndTime && archiveDateAndTime && (pageType === "LINK")) ? (
                                            <div className="d-flex date-text dates">
                                                <span>
                                                    {convertToDate(
                                                        publishDateAndTime,
                                                        "preview"
                                                    ) || t("common.fieldHeaders.dateFrom")}
                                                </span>
                                                <div>&nbsp; - &nbsp;</div>
                                                <span>
                                                    {convertToDate(archiveDateAndTime, "preview") ||
                                                        t("common.fieldHeaders.dateTo")}
                                                </span>
                                            </div>
                                        ) : null
                                    }
                                    {
                                        pageType === "LINK" && (
                                            <div className="w-100">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className="w-100"
                                                    size="small"
                                                >
                                                    {getValues('button_text') ? getValues('button_text') : t('common.buttons.buttonText')}
                                                </Button>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }
            ></PreviewModal>
            <NotificationModal
                openModal={openNotificationModal}
                buttonText={t("common.buttons.close")}
                handleClose={handleNotificationClose}
                handleButtonClick={handleNotificationClose}
                message={notificationMessage}
            />
            {innerLoading ? <Spinner text={t("common.uploading")} /> : null}
        </div>
    );
};

export default ViewSale;
