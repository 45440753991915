import {
  Button,
  IconButton,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import ImagePlaceholder from "../../../assets/images/image-placeholder-horizontal.png";
import TextFieldIcon from "../../../assets/images/benefits/text-field.png";
import DropdownIcon from "../../../assets/images/benefits/dropdown.png";
import CheckboxIcon from "../../../assets/images/benefits/checkbox.png";
import RadioButtonIcon from "../../../assets/images/benefits/radio-button.png";
import UploadFile from "../../../components/UploadFile/UploadFile";
import "./style.scss";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import PreviewModal from "../../../components/PreviewModal/PreviewModal";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FIELDS, VALIDATIONS } from "./saleFields";
import { Editor } from "@tinymce/tinymce-react";
import { useStore } from "../../../store/StoreContext";
import { flowResult } from "mobx";
import NotificationModal from "../../../components/NotificationModal/NotificationModal";
import { useHistory } from "react-router-dom";
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import DeleteIcon from "../../../assets/icons/delete.svg";
import AddIcon from "@material-ui/icons/Add";
import Spinner from "../../../components/Spinner/Spinner";
import { observer } from "mobx-react-lite";
import ProceedImage from "../../../shared/utils/proceedImage";
import { useTranslation } from "react-i18next";
import heLocale from "date-fns/locale/he";
import enLocale from "date-fns/locale/en-US";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../shared/utils/cropImage";
import UserTypeEnum from "../../../models/UserType";
import { convertLocalToZuluTime, convertToDate, getElementFromArrayObject } from "../../../shared/utils/sharedFunctions";
import UnsavedChangesPrompt from "../../../shared/components/UnsavedChangesPrompt/UnsavedChangesPrompt";
import { tinyEditorOptions } from "../../../shared/utils/sharedOptions";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { compressAccurately } from "image-conversion";

const NewSale = () => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const {
    benefitStore,
    actionStore,
    languageStore,
    archiveStore,
    userStore,
    groupStore,
    departmentStore
  } = useStore();
  const [isPushNotification, setIsPushNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [innerLoading, setInnerLoading] = useState(false);
  const [header, setHeader] = useState("");
  const [description, setDescription] = useState("");
  const [textEditor, setTextEditor] = useState("");
  const [pageType, setPageType] = useState("");
  const [isRedirect, setIsRedirect] = useState(true);
  const [roleType, setRoleType] = useState(null);
  const [archiveDays, setArchiveDays] = useState(7);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const resolutionIsOrBelow1440 = window.innerWidth <= 1440;
  const resolutionAbove1024 = window.innerWidth > 1024;
  const resolutionIs1024 = window.innerWidth === 1024;
  const getCropWidth = () => {
    if (!resolutionIsOrBelow1440) return 403;
    else if (resolutionIsOrBelow1440 && resolutionAbove1024) return 322.4;
    else if (resolutionIs1024) return 268.75;
    return 201.6;
  };
  const getCropHeight = () => {
    if (!resolutionIsOrBelow1440) return 268.6;
    else if (resolutionIsOrBelow1440 && resolutionAbove1024) return 214.9;
    else if (resolutionIs1024) return 179.1;
    return 134.4;
  };

  const [categories, setCategories] = useState([
    {
      id: "",
      categoryName: "",
      categoryType: "",
    },
  ]);

  const [groups, setGroups] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [secondaryDepartments, setSecondaryDepartments] = useState<any>([]);
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(true);
  const [isSelectAllSelectedSecondary, setIsSelectAllSelectedSecondary] = useState(true);

  const [fileUpload, setFileUpload] = useState({
    lastModified: 0,
    lastModifiedDate: new Date(),
    name: "",
    size: 0,
    type: "",
    webkitRelativePath: "",
    url: ImagePlaceholder,
  });
  const [imageOriginal, setImageOriginal] = useState("");

  // Preview Modal
  const [openModal, setOpenModal] = useState(false);
  // Notification Modal
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const [formBuilder, setFormBuilder] = useState([
    {
      type: "placeholder",
      label: "",
      options: [
        {
          key: "",
          value: "",
        },
      ],
      is_required: false,
    },
  ]);

  const addForm = (type) => {
    let form = {};
    if (type === "textfield") {
      form = {
        type: type,
        label: "",
      };
    }
    if (type === "radio" || type === "dropdown" || type === "checkbox") {
      form = {
        type: type,
        label: "",
        options: [
          {
            key: 1,
            value: "",
          },
          {
            key: 2,
            value: "",
          },
        ],
      };
    }
    if (pageType === "SURVEY") {
      form['is_required'] = false;
    }
    const newForm = [...formBuilder];
    // @ts-ignore
    newForm.push(form);
    setFormBuilder(newForm);
    // @ts-ignore
    setValue("fields", newForm);
  };

  const addFormOption = (itemIndex) => {
    const newForm = formBuilder.map((form: any, index) => {
      if (itemIndex === index) {
        // key starts from 1, not 0, thats why key is length + 1
        return { ...form, options: [...form.options, { key: form.options.length + 1, value: "" }] };
      }
      return form;
    });
    setFormBuilder(newForm);
  };

  const deleteForm = (itemIndex) => {
    const newForm = formBuilder.filter((form, index) => {
      return itemIndex !== index;
    });
    setFormBuilder(newForm);
    // @ts-ignore
    setValue("fields", newForm);
  };

  const deleteFormOption = (itemIndex, optionIndex) => {
    const newForm = formBuilder.map((form, index) => {
      if (itemIndex === index) {
        return {
          ...form,
          options: [
            ...form.options.filter((opt, optIndex) => optIndex !== optionIndex),
          ],
        };
      }
      return form;
    });
    setFormBuilder(newForm);
  };

  const isCharOfLabelIsNumber = (label) => {
    if (!label.length) return false;
    return label[0] <= '9' && label[0] >= '0'
  }

  const handleLabelChangeOnForm = (itemIndex, value) => {
    const newForm = formBuilder.map((form, index) => {
      if (itemIndex === index) {
        return { ...form, label: value };
      }
      return form;
    });
    setFormBuilder(newForm);
  };

  const handleOptionChangeOnForm = (
    itemIndex,
    itemType,
    optionIndex,
    value
  ) => {
    const newForm = formBuilder.map((form, index) => {
      if (itemIndex === index) {
        return {
          ...form,
          options: [
            ...form.options.map((opt, optIndex) => {
              if (optIndex === optionIndex) {
                return { ...opt, [itemType]: value };
              }
              return opt;
            }),
          ],
        };
      }
      return form;
    });
    setFormBuilder(newForm);
  };

  const handleIsRequiredChangeOnForm = (itemIndex) => {
    const newForm = formBuilder.map((form, index) => {
      if (itemIndex === index) {
        return { ...form, is_required: !form.is_required };
      }
      return form;
    });
    setFormBuilder(newForm);
  };

  const [showImage, setShowImage] = useState(false);

  const handleFileUpload = async (data) => {
    if (data) {
      setInnerLoading(true);
      setShowImage(true);
      const image: any = await ProceedImage.toBase64(data);
      setFileUpload({ ...data, url: image });
      setValue("image", image);

      // Save original image
      let compressed: Blob;
      if (data.type.indexOf("image") !== -1) {
        // Maximum for firbase is 300kb
        compressed = await compressAccurately(data, {size: 280, accuracy: 0.99});
      }
      const formData = new FormData();
      formData.append("file", compressed || data);
      const imageToURL = await flowResult(actionStore.uploadFile(formData)).then(
        (response: any) => {
          setIsLoading(false);
          return response;
        }
      );
      setImageOriginal(imageToURL);

      //Initiate the FileReader object.
      const reader = new FileReader();
      //Read the contents of Image File.
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        let image: any = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          const height: number = Number(this.height);
          const width: number = Number(this.width);

          if (width > height * 1.488) {
            let zoom = (width / height * 0.67);
            zoom = zoom >= 1 ? zoom : zoom;
            setZoom(zoom);
            setMaxZoom(zoom * 4);
          } else {
            setZoom(1);
            setMaxZoom(4);
          }
          setTimeout(() => {
            const cropperImage: any = document.querySelector('.reactEasyCrop_Image');
            if (cropperImage.className.indexOf('reactEasyCrop_Cover_Vertical') > -1) {
              cropperImage.style.height = '100.1%';
            } else if (cropperImage.className.indexOf('reactEasyCrop_Cover_Horizontal') > -1) {
              cropperImage.style.width = '100.1%';
            }
          })
        }
      };
      reader.readAsDataURL(data);
      setIsNewPictureUploaded(true);
      setInnerLoading(false)
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(VALIDATIONS),
    mode: "onChange",
    defaultValues: {
      // Primary
      category_id: "",
      page_type: "",
      header: "",
      description: "",
      image: "",
      appears: [],
      from_date: null,
      to_date: null,
      is_push_notification: false,
      push_notification_text: "",
      publishWhen: "now",
      publish_date: convertToDate(new Date()),
      archiveWhen: "globalDate",
      archive_date: convertToDate(
        new Date(new Date().setDate(new Date().getDate() + 1))
      ),
      // Leads
      date_from: convertToDate(new Date()),
      date_to: convertToDate(
        new Date(new Date().setDate(new Date().getDate() + 1))
      ),
      details: "write",
      external_link: "",
      supplier_email: "",
      supplier_second_email: "",
      // Leads - form builder
      fields: [],

      // Link
      button_text: "",
      link: "",

      // Store
      date: null,
      hour: null,
      location: "",
      number_of_tickets: 0,
      max_purchases: 0,
      price: 0,

      // Used in Store & Leads
      details_header: "",
      details_text: " ",
      is_draft: false,
    },
  } as any);

  const watchDraftFields = watch(["category_id", "page_type", "header"]);
  const watchAllFields = watch();

  useEffect(() => {
    const subscription = watch(() => {
      if (watchDraftFields.filter(el => el).length === 3) {
        setUnsavedChanges(true);
      } else {
        setUnsavedChanges(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [watchDraftFields])

  const [submitError, setSubmitError] = useState("");

  useEffect(() => {
    Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
  }, [register]);

  useEffect(() => {
    const storedBenefit = benefitStore.getStoredBenefits();
    const storedGroups = groupStore.getStoredGroups();
    const storedDepartments = departmentStore.getStoredDepartments();
    const storedSecondaryDepartments = departmentStore.getStoredSecondaryDepartments();
    const archiveDays = archiveStore.getBenefitArchiveDays();
    const roleType = userStore.getRoleType();
    setRoleType(roleType);
    if (roleType === UserTypeEnum.owner) {
      setValue("archiveWhen", "specificDate");
    } else {
      setArchiveDays(archiveDays);
      setTimeout(() => {
        setArchiveDateAndTime(new Date(new Date().setDate(new Date().getDate() + archiveDays)))
      })
      setValue("archive_date", convertToDate(
        new Date(new Date().setDate(new Date().getDate() + archiveDays))
      ))
    }
    setGroups(storedGroups);
    setDepartments(storedDepartments);
    setSecondaryDepartments(storedSecondaryDepartments);
    setCategories(storedBenefit);
    const appears = roleType === UserTypeEnum.owner ? storedGroups.map(group => group.id) : storedDepartments.map(department => department.id).concat(storedSecondaryDepartments.map(department => department.id));
    setValue("appears", [...appears, 'select-all']);
    setIsLoading(false);
  }, [benefitStore.benefits]);

  const stringToCapital = (text) => {
    if (text && text.length > 1) {
      text = text.toLowerCase();
      return text?.charAt(0).toUpperCase() + text?.slice(1);
    }
    return text;
  };

  const returnTypeData = async (objectData, isDraft = false) => {
    let data = {};
    let croppedImage: any = "";
    if (isNewPictureUploaded) {
      croppedImage = await cropImage(croppedAreaPixels);
    }
    const publishDate = objectData.publish === "now" ? convertLocalToZuluTime(new Date()) : convertLocalToZuluTime(publishDateAndTime);
    const archiveDate = objectData.archive === "globalDate" ? convertLocalToZuluTime(new Date(new Date().setDate(new Date().getDate() + archiveDays)))
      : convertLocalToZuluTime(archiveDateAndTime);
    data = {
      item_type: pageType,
      category_id: objectData.category_id,
      appears: objectData.appears.filter((el) => el !== 'select-all'),
      from_date: publishDate,
      to_date: archiveDate,
      header: objectData.header,
      description: objectData.description,
      image: croppedImage || objectData.image,
      image_original: imageOriginal ?? "",
      publish_date: publishDate,
      archive_date: archiveDate,
      is_push_notification: objectData.is_push_notification,
      push_notification_text: objectData.push_notification_text,
      is_draft: isDraft,
    }
    if (pageType === "LEADS") {
      const emails = [objectData.supplier_email];
      if (objectData.supplier_second_email) {
        emails.push(objectData.supplier_second_email)
      }
      data = {
        ...data,
        item_type: pageType,
        details: {
          supplier_email: [...emails],
          date_from: objectData.date_from,
          date_to: objectData.date_to,
          details_header: objectData.header,
          details_text: textEditor,
          form_builder: {
            fields: [...formBuilder].filter((el) => el.type !== "placeholder"),
          },
        },
      };
    }
    if (pageType === "LINK") {
      data = {
        ...data,
        item_type: pageType,
        details: {
          button_text: objectData.button_text,
          link: objectData.link,
        },
      };
    }
    if (pageType === "STORE") {
      data = {
        ...data,
        item_type: pageType,
        details: {
          date: storeDateAndTime,
          hour: storeDateAndTime,
          location: objectData.location,
          number_of_tickets: objectData.number_of_tickets,
          max_purchases: objectData.max_purchases,
          price: objectData.price,
          details_header: objectData.header,
          details_text: textEditor,
        },
      };
    }
    if (pageType === "SURVEY") {
      const emails = [objectData.supplier_email];
      if (objectData.supplier_second_email) {
        emails.push(objectData.supplier_second_email)
      }
      data = {
        ...data,
        item_type: pageType,
        details: {
          supplier_email: [...emails],
          form_builder: {
            fields: [...formBuilder].filter((el) => el.type !== "placeholder"),
          },
        },
      };
    }
    return data;
  };

  const createBenefit = async (data) => {
    setIsLoading(true);
    setSubmitError("");
    if (isNewPictureUploaded) {
      const formData = new FormData();
      formData.append("file", data.image);
      const croppedImage = await flowResult(actionStore.uploadFile(formData)).then(
        (response: any) => {
          return response;
        }
      );
      data = { ...data, image: croppedImage }
    }
    await flowResult(actionStore.create(data, "benefit")).then((response: any) => {
      setIsLoading(false);
      if (!response?.success) {
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0"),
          false
        );
        return;
      }
      handleNotificationOpen(
        response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"),
        true
      );
    });
  };

  const handleSubmitDraft = async (objectData) => {
    let data = await returnTypeData(objectData, true);
    const dataKeys = Object.keys(data);
    dataKeys.forEach((el) => {
      if (!data[el]) delete data[el];
      if (el === "details") {
        const detailsKeys = Object.keys(data[el]);
        detailsKeys.forEach((det) => {
          // @ts-ignore
          if (!data.details[det]) delete data.details[det];
        });
      }
    });
    createBenefit(data);
  };

  const [potentialData, setPotentialData] = useState({});

  const handleSubmitCheck = async (objectData) => {
    if (formBuilder.filter(el => isCharOfLabelIsNumber(el.label)).length > 0) {
      return;
    }
    if (objectData.is_draft) {
      handleSubmitDraft(objectData);
      return;
    }
    if (publishInvalidDate || archiveInvalidDate) {
      return;
    }
    let data = await returnTypeData(objectData);
    setPotentialData(data);
    setOpenModal(true);
  };

  const handleSaveChanges = async () => {
    setUnsavedChanges(false);
    let data = await returnTypeData(watchAllFields, true);
    const dataKeys = Object.keys(data);
    dataKeys.forEach((el) => {
      if (!data[el]) delete data[el];
      if (el === "details") {
        const detailsKeys = Object.keys(data[el]);
        detailsKeys.forEach((det) => {
          // @ts-ignore
          if (!data.details[det]) delete data.details[det];
        });
      }
    });
    createBenefit(data);
  }

  const handleCloseModal = (isSave) => () => {
    setOpenModal(false);
    if (isSave) {
      let data = potentialData;
      const dataKeys = Object.keys(data);
      dataKeys.forEach((el) => {
        if (!data[el]) delete data[el];
        if (el === "details") {
          const detailsKeys = Object.keys(data[el]);
          detailsKeys.forEach((det) => {
            // @ts-ignore
            if (!data.details[det]) delete data.details[det];
          });
        }
      });
      setSubmitError("");
      createBenefit(data);
    }
  };

  const handleNotificationOpen = (message = "", isRedirect) => {
    setNotificationMessage(message);
    setIsRedirect(isRedirect);
    setOpenNotificationModal(true);
  };

  const handleNotificationClose = () => {
    setOpenNotificationModal(false);
    if (isRedirect) {
      history.push("/benefits/list");
    }
  };

  // Detecting Language Switch
  const [lngDirection, setLngDirection] = useState("");

  useEffect(() => {
    setIsLoading(true);
    const dir = i18n.dir();
    setLngDirection(dir);
    setTimeout(() => {
      setIsLoading(false)
    })
    clearFieldsPerType("all");
  }, [languageStore.switchLng]);

  const textInputDirection = () => {
    if (lngDirection === "rtl") return "rtl";
    if (lngDirection === "ltr") return "ltr";
  };

  const transformEditorText = (text) => {
    if (text && text.length > 0) {
      return text
        .replace(/(<([^>]+)>)/gi, "")
        // eslint-disable-next-line no-useless-escape
        .replace(/\&nbsp;/g, "")
        .trim();
    }
    return text;
  };

  // Ref
  const categoryRef: any = useRef(null);
  const pageTypeRef: any = useRef(null);
  const headerRef: any = useRef(null);
  const descriptionRef: any = useRef(null);
  const imageRef: any = useRef(null);
  const appearsRef: any = useRef(null);
  const pushTextRef: any = useRef(null);
  const detailsEmailRef: any = useRef(null);
  const fieldsRef: any = useRef(null);
  const buttonRef: any = useRef(null);
  const linkRef: any = useRef(null);
  const locationRef: any = useRef(null);
  const ticketsRef: any = useRef(null);
  const maxPurchasesRef: any = useRef(null);
  const priceRef: any = useRef(null);
  const detailsHeaderRef: any = useRef(null);
  const detailsTextRef: any = useRef(null);
  const publishDateRef: any = useRef(null);
  const archiveDateRef: any = useRef(null);

  const scrollToError = () => {
    if (errors?.category_id?.message) {
      categoryRef.current.scrollIntoView();
    } else if (errors?.page_type?.message) {
      pageTypeRef.current.scrollIntoView();
    } else if (errors?.header?.message) {
      headerRef.current.scrollIntoView();
    } else if (errors?.description?.message) {
      descriptionRef.current.scrollIntoView();
    } else if (errors?.image?.message && !isNewPictureUploaded) {
      imageRef.current.scrollIntoView();
      // @ts-ignore
    } else if (errors?.appears?.message) {
      appearsRef.current.scrollIntoView();
    } else if (errors?.push_notification_text?.message) {
      pushTextRef.current.scrollIntoView();
    } else if ((errors?.supplier_email?.message || errors?.supplier_second_email?.message)) {
      detailsEmailRef.current.scrollIntoView();
      // @ts-ignore
    } else if (errors?.supplier_second_email?.message) {
      detailsEmailRef.current.scrollIntoView();
      // @ts-ignore
    } else if (errors?.fields?.message) {
      fieldsRef.current.scrollIntoView();
    } else if (errors?.button_text?.message) {
      buttonRef.current.scrollIntoView();
    } else if (errors?.link?.message) {
      linkRef.current.scrollIntoView();
    } else if (errors?.location?.message) {
      locationRef.current.scrollIntoView();
    } else if (errors?.number_of_tickets?.message) {
      ticketsRef?.current.scrollIntoView();
    } else if (errors?.max_purchases?.message) {
      maxPurchasesRef?.current.scrollIntoView();
    } else if (errors?.price?.message) {
      priceRef?.current.scrollIntoView();
    } else if (errors?.details_text?.message) {
      detailsTextRef.current.scrollIntoView();
    } else if (publishInvalidDate) {
      publishDateRef.current.scrollIntoView();
    } else if (archiveInvalidDate) {
      archiveDateRef.current.scrollIntoView();
    }
    const element = document.querySelector('.main-content-container');
    if (element) {
      element.scrollTo(0, 0);
    }
  };

  const clearFieldsPerType = (type) => {
    if (type === pageType && type !== "all") {
      return;
    }
    if (type === "all") {
      setValue("category_id", "");
      setValue("page_type", "");
      setPageType("");
      setValue("header", "");
      setHeader("");
      setValue("description", "");
      setDescription("");
      setValue("image", "");
      setImageOriginal("");
      setFileUpload({
        lastModified: 0,
        lastModifiedDate: new Date(),
        name: "",
        size: 0,
        type: "",
        webkitRelativePath: "",
        url: ImagePlaceholder,
      });
      setShowImage(false);
      setValue("from_date", null);
      setValue(
        "to_date",
        null
      );
      setValue("is_push_notification", false);
      setIsPushNotification(false);
      setValue("push_notification_text", "");
      setValue("publish_date", convertToDate(new Date()));
      setPublishDateAndTime(new Date());
      setPublishHourAndMinutes({
        hour: new Date().getHours(),
        minutes: new Date().getMinutes(),
      });
      setValue("archive_date", convertToDate(new Date(new Date().setDate(new Date().getDate() + archiveDays))));
      setArchiveDateAndTime(new Date(new Date().setDate(new Date().getDate() + archiveDays)));
      setArchiveHourAndMinutes({
        hour: new Date().getHours(),
        minutes: new Date().getMinutes(),
      });
    }
    // Leads
    setValue("date_from", convertToDate(new Date()));
    setValue(
      "date_to",
      convertToDate(new Date(new Date().setDate(new Date().getDate() + 1)))
    );
    setValue("details", "write");
    setValue("external_link", "");
    // Leads - form builder
    setValue("supplier_email", "");
    setValue("supplier_second_email", "");
    setValue("fields", []);
    setFormBuilder([]);

    // Link
    setValue("button_text", "");
    setValue("link", "");

    // Store
    setValue("date", null);
    setValue("hour", null);
    setValue("location", "");
    setValue("number_of_tickets", 0);
    setValue("max_purchases", 0);
    setValue("price", 0);

    // Used in Store & Leads
    setValue("details_header", "");
    setValue("details_text", "");
    setTextEditor("");
  };

  const [publishDateAndTime, setPublishDateAndTime] = useState(new Date());
  const [publishHourAndMinutes, setPublishHourAndMinutes] = useState({
    hour: 0,
    minutes: 0,
  });

  const [archiveDateAndTime, setArchiveDateAndTime] = useState(
    new Date(new Date().setDate(new Date().getDate() + 7))
  );
  const [archiveHourAndMinutes, setArchiveHourAndMinutes] = useState({
    hour: 0,
    minutes: 0,
  });

  const [storeDateAndTime, setStoreDateAndTime] = useState(null);
  const [storeHourAndMinutes, setStoreHourAndMinutes] = useState({
    hour: 0,
    minutes: 0,
  });

  const changeDateOnly = (
    date,
    hourAndMinutes,
    setDateAndTime,
    setInvalidDate?,
    type = ""
  ) => {
    if (date instanceof Date) {
      const hours = hourAndMinutes.hour;
      const minutes = hourAndMinutes.minutes;
      if (!isNaN(hours) && !isNaN(minutes))
        setDateAndTime((v: any) => {
          if (date) {
            date = new Date(date);
            date.setHours(hours, minutes);
            const newDate = new Date(date);
            return newDate;
          }
          return new Date();
        });
    } else {
      setDateAndTime(null)
    }
  };

  const changeTimeOnly = (
    time,
    setDateAndTime,
    setHourAndMinutes,
    setInvalidDate?,
    type = ""
  ) => {
    if (time instanceof Date) {
      if (!isNaN(time.getHours()) && !isNaN(time.getMinutes()))
        setDateAndTime((date: any) => {
          if (date) {
            date.setHours(time.getHours(), time.getMinutes());
            const newDate = new Date(date);
            setHourAndMinutes({
              hour: time.getHours(),
              minutes: time.getMinutes(),
            });
            return newDate;
          }
          return new Date();
        });
    } else {
      setDateAndTime(null)
    }
  };

  const [publishInvalidDate, setPublishInvalidDate] = useState(false);
  const [archiveInvalidDate, setArchiveInvalidDate] = useState(false);

  // Cropper
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [maxZoom, setMaxZoom] = useState(4);
  const [croppedImage, setCroppedImage] = useState<any>("");
  const [isNewPictureUploaded, setIsNewPictureUploaded] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async (croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(
      fileUpload?.url,
      croppedAreaPixels
    );
    const croppedImageBlob = await getCroppedImg(
      fileUpload?.url,
      croppedAreaPixels,
      true
    );
    setCroppedImage(croppedImage);
    return croppedImageBlob;
  };

  const onDepartmentSelectChange = (data, isSelectAll = false) => {
    const secondaries = getValues('appears').filter(depID => secondaryDepartments.some(dep => dep.id === depID))
    setValue('appears', [...data, ...secondaries]);
    setIsSelectAllSelected(isSelectAll)
  }
  const onSecondaryDepartmentSelectChange = (data, isSelectAll = false) => {
    const deps = getValues('appears').filter(depID => departments.some(dep => dep.id === depID))
    setValue('appears', [...data, ...deps]);
    setIsSelectAllSelectedSecondary(isSelectAll);
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <UnsavedChangesPrompt
        when={unsavedChanges}
        onSave={() => handleSaveChanges()}
        onCancel={() => true}
      />
      <div className="create-benefit">
        <form
          className=""
          autoComplete="off"
          onSubmit={handleSubmit((d) => handleSubmitCheck(d))}
        >
          <div className="main-wrapper benefit-page">
            <div className="create-options">
              <h2 className="page-heading">{t("benefits.create.title")}</h2>
              <div className="main-wrapper benefit-wrapper">
                <p>{t("benefits.create.form.title")}</p>
                <Grid container className="create-grid">
                  <Grid item xs={4} lg={3} ref={categoryRef}>
                    <FormControl>
                      <InputLabel id="category-label">
                        {t("common.fieldHeaders.category")}
                      </InputLabel>
                      <Controller
                        control={control}
                        name="category_id"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <Select
                              labelId="category-label"
                              id="category"
                              displayEmpty
                              value={value}
                              defaultValue=""
                              onChange={onChange}
                              disabled={!categories.length}
                            >
                              {categories.map((cat, index) => (
                                <MenuItem key={index} value={cat.id}>
                                  {cat.categoryName}
                                </MenuItem>
                              ))}
                            </Select>
                            {categories.length ? (
                              <p className="field-error">
                                {errors?.category_id?.message ?? ""}&nbsp;
                              </p>
                            ) : (
                              <p className="field-error">
                                {t("errorMessages.articles.create.noCategories")}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <div className="separator" />
                  <Grid item xs={4} lg={3} ref={pageTypeRef}>
                    <FormControl>
                      <InputLabel id="item_type-label">
                        {t("common.fieldHeaders.pageType")}
                      </InputLabel>
                      <Controller
                        control={control}
                        name="page_type"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <Select
                              labelId="item_type-label"
                              id="item_type"
                              displayEmpty
                              onChange={(e: any) => {
                                const type = e.target.value;
                                setPageType(type);
                                clearFieldsPerType(type);
                                onChange(e);
                              }}
                              value={value}
                            >
                              <MenuItem value="LEADS">
                                {t("common.selectFilters.leads")}
                              </MenuItem>
                              <MenuItem value="LINK">
                                {t("common.selectFilters.link")}
                              </MenuItem>
                              <MenuItem value="STORE">
                                {t("common.selectFilters.store")}
                              </MenuItem>
                              <MenuItem value="SURVEY">
                                {t("common.selectFilters.survey")}
                              </MenuItem>
                            </Select>
                            <p className="field-error">
                              {errors?.page_type?.message ?? ""}&nbsp;
                            </p>
                          </>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="create-preview">
              <div className="main-wrapper">
                <h3>{t("common.buttons.preview")}</h3>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={lngDirection === "rtl" ? heLocale : enLocale}
                >
                  <Grid container className="create-grid" justifyContent="space-between">
                    <Grid item xs={8} lg={6}>
                      <Grid container direction="column">
                        <Grid item className="grid-row" xs={9} ref={headerRef}>
                          <Controller
                            control={control}
                            name="header"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <TextField
                                  id="header"
                                  label={t("common.fieldHeaders.header")}
                                  value={value}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    onChange(e);
                                    setHeader(value);
                                    setValue('header', value);
                                  }}
                                />
                                <p className="field-error">
                                  {errors?.header?.message ?? ""}&nbsp;
                                </p>
                              </>
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          className="grid-row"
                          xs={9}
                          ref={descriptionRef}
                        >
                          <Controller
                            control={control}
                            name="description"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <TextField
                                  id="description"
                                  label={t("common.fieldHeaders.description")}
                                  value={value}
                                  onChange={(e) => {
                                    setDescription(e.target.value);
                                    onChange(e);
                                  }}
                                />
                                <p className="field-error">
                                  {errors?.description?.message ?? ""}&nbsp;
                                </p>
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item className="grid-row" xs={9} ref={imageRef}>
                          <UploadFile
                            text={t("common.fieldHeaders.uploadImage")}
                            handleFileUpload={handleFileUpload}
                            showImage={showImage}
                            image={fileUpload.url}
                            height={115}
                            width={175}
                          />
                          <span className="information-message">{t("informationMessages.imageOnly")}</span>
                          <p className="field-error">
                            {!showImage ? errors?.image?.message ?? "" : ""}&nbsp;
                          </p>
                        </Grid>
                        <Grid item xs={9} ref={appearsRef}>
                          <FormControl>
                            <Controller
                              control={control}
                              name="appears"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <label className="field-label">
                                    {t("common.fieldHeaders.appears")}
                                  </label>
                                  {roleType !== UserTypeEnum.owner && <><br />
                                  <label className="field-label">{t('common.fieldHeaders.department')}</label></>}
                                  <MultiSelect
                                    showSelectAll
                                    value={roleType === UserTypeEnum.owner ? value : value.filter(depID => departments.some(dep => dep.id === depID))}
                                    hasError={!!errors?.appears?.message}
                                    isSelectAllSelected={isSelectAllSelected}
                                    mapBy="id"
                                    renderedName="name"
                                    onChange={onDepartmentSelectChange}
                                    selectOptions={roleType === UserTypeEnum.owner ? groups : departments}
                                  />
                                  {(roleType === UserTypeEnum.owner &&
                                    groups.length) ||
                                    (roleType !== UserTypeEnum.owner &&
                                      departments.length) ? (
                                    <>
                                      <p className="field-error">
                                        {/* @ts-ignore */}
                                        {errors?.appears?.message ??
                                          ""}&nbsp;{" "}
                                      </p>
                                    </>
                                  ) : (
                                    <p className="field-error">
                                      {roleType === UserTypeEnum.owner
                                        ? t(
                                          "errorMessages.articles.create.noGroups"
                                        )
                                        : t(
                                          "errorMessages.articles.create.noDepartments"
                                        )}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </FormControl>
                        </Grid>
                        {roleType !== UserTypeEnum.owner && secondaryDepartments.length > 0 && <Grid item xs={9}>
                          <FormControl>
                            <Controller
                              control={control}
                              name="appears"
                              render={({ field: { onChange, value } }) => (
                                <>
                                <label className="field-label">{t('common.fieldHeaders.secondaryDepartment')}</label>
                                <MultiSelect
                                    showSelectAll
                                    value={value.filter(depID => secondaryDepartments.some(dep => dep.id === depID))}
                                    isSelectAllSelected={isSelectAllSelectedSecondary}
                                    mapBy="id"
                                    renderedName="name"
                                    onChange={onSecondaryDepartmentSelectChange}
                                    selectOptions={secondaryDepartments}
                                  />
                                </>
                              )}
                            />
                          </FormControl>
                        </Grid>}
                        <Grid item className="grid-row" xs={9} ref={pushTextRef}>
                          <FormControlLabel
                            control={
                              <Controller
                                control={control}
                                name="is_push_notification"
                                render={({ field: { onChange, value } }) => (
                                  <Checkbox
                                    color="primary"
                                    checked={value || false}
                                    onChange={onChange}
                                    onClick={() => {
                                      setIsPushNotification(!value);
                                      if (value) {
                                        setValue("push_notification_text", "");
                                      }
                                    }}
                                  />
                                )}
                              />
                            }
                            label={t("common.fieldHeaders.sendPushNotification")}
                            onClick={() =>
                              getValues("push_notification_text")
                                ? setValue("push_notification_text", "")
                                : null
                            }
                          />
                          <Controller
                            control={control}
                            name="push_notification_text"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <TextField
                                  id="pushNotificationText"
                                  label={t(
                                    "common.fieldHeaders.pushNotificationText"
                                  )}
                                  value={value}
                                  onChange={onChange}
                                  disabled={!isPushNotification}
                                />
                                <p className="field-error">
                                  {errors?.push_notification_text?.message ?? ""}
                                  &nbsp;
                                </p>
                              </>
                            )}
                          />
                        </Grid>

                        <Grid item className="grid-row" xs={9}>
                          <p>{t("common.fieldHeaders.whenToPublishBenefit")}</p>
                          <Controller
                            control={control}
                            name="publishWhen"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <RadioGroup name="publish" value={value}>
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        color="primary"
                                        onChange={(e) => {
                                          onChange(e);
                                          setValue(
                                            "publish_date",
                                            convertToDate(new Date())
                                          );
                                        }}
                                      />
                                    }
                                    value="now"
                                    label={t("common.fieldHeaders.now")}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        color="primary"
                                        onChange={onChange}
                                      />
                                    }
                                    value="later"
                                    label={t("common.fieldHeaders.later")}
                                  />
                                </RadioGroup>
                                {value === "later" && (
                                  <Grid container justifyContent="space-between" ref={publishDateRef}>
                                    <Grid item xs={7}>
                                      <KeyboardDatePicker
                                        // disablePast
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="publishDate-picker-inline"
                                        className="date-picker-inline"
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                        onChange={(value: any) => {
                                          changeDateOnly(
                                            value,
                                            publishHourAndMinutes,
                                            setPublishDateAndTime,
                                            setPublishInvalidDate,
                                            "publish"
                                          );
                                        }}
                                        value={convertToDate(publishDateAndTime)}
                                        autoOk={true}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <KeyboardTimePicker
                                        margin="normal"
                                        id="time-picker"
                                        okLabel={t("timePicker.confirm")}
                                        cancelLabel={t("timePicker.cancel")}
                                        value={publishDateAndTime}
                                        onChange={(value: any) => {
                                          changeTimeOnly(
                                            value,
                                            setPublishDateAndTime,
                                            setPublishHourAndMinutes,
                                            setPublishInvalidDate,
                                            "publish"
                                          );
                                        }}
                                        KeyboardButtonProps={{
                                          "aria-label": "Hour",
                                        }}
                                      />
                                    </Grid>
                                    <p className="field-error">
                                      {publishInvalidDate
                                        ? t("push.errors.time")
                                        : ""}
                                      &nbsp;
                                    </p>
                                  </Grid>
                                )}
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item className="grid-row" xs={9}>
                          <p>{t("common.fieldHeaders.whenToArchiveBenefit")}</p>
                          <Controller
                            control={control}
                            name="archiveWhen"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <RadioGroup name="archive" value={value}>
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        color="primary"
                                        onChange={onChange}
                                      />
                                    }
                                    value="globalDate"
                                    label={t("common.fieldHeaders.globalDate")}
                                    disabled={roleType === UserTypeEnum.owner}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        color="primary"
                                        onChange={onChange}
                                      />
                                    }
                                    value="specificDate"
                                    label={t("common.fieldHeaders.specificDate")}
                                  />
                                </RadioGroup>
                                {value === "specificDate" && (
                                  <Grid container justifyContent="space-between" ref={archiveDateRef}>
                                    <Grid item xs={7}>
                                      <KeyboardDatePicker
                                        // minDate={publishDateAndTime}
                                        minDateMessage={t('errorMessages.minimumDate')}
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="archiveDate-picker-inline"
                                        className="date-picker-inline"
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                        onChange={(value: any) => {
                                          changeDateOnly(
                                            value,
                                            archiveHourAndMinutes,
                                            setArchiveDateAndTime,
                                            setArchiveInvalidDate,
                                            "archive"
                                          );
                                        }}
                                        value={convertToDate(archiveDateAndTime)}
                                        autoOk={true}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <KeyboardTimePicker
                                        margin="normal"
                                        id="time-picker"
                                        okLabel={t("timePicker.confirm")}
                                        cancelLabel={t("timePicker.cancel")}
                                        value={archiveDateAndTime}
                                        onChange={(value: any) => {
                                          changeTimeOnly(
                                            value,
                                            setArchiveDateAndTime,
                                            setArchiveHourAndMinutes,
                                            setArchiveInvalidDate,
                                            "archive"
                                          );
                                        }}
                                        KeyboardButtonProps={{
                                          "aria-label": "Hour",
                                        }}
                                      />
                                    </Grid>
                                    <p className="field-error">
                                      {archiveInvalidDate
                                        ? t("push.errors.time")
                                        : ""}
                                      &nbsp;
                                    </p>
                                  </Grid>
                                )}
                              </>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      className="preview-container"
                      style={{ zIndex: 9 }}
                    >
                      <div className="fixed">
                        <div className={`preview-image`}>
                          {!isNewPictureUploaded ? (
                            <img
                              className="no-crop"
                              src={fileUpload?.url ? fileUpload?.url : " "}
                              alt={fileUpload?.name ?? ""}
                            />
                          ) : (
                            <div className="crop-container">
                              <Cropper
                                image={fileUpload?.url ? fileUpload?.url : " "}
                                crop={crop}
                                zoom={zoom}
                                zoomSpeed={0.1}
                                minZoom={0.05}
                                maxZoom={maxZoom}
                                restrictPosition={false}
                                objectFit="horizontal-cover"
                                cropSize={{
                                  width: getCropWidth(),
                                  height: getCropHeight(),
                                }}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                              />
                            </div>
                          )}
                        </div>
                        <div className="preview-text-container">
                          <div className="preview-text">
                            <div>
                              {
                                (pageType === "STORE" || pageType === "SURVEY") && (
                                  <div className="preview-category">
                                    {getValues('category_id') ? getElementFromArrayObject('id', 'categoryName', getValues('category_id'), categories) : t('common.fieldHeaders.category')}
                                  </div>
                                )
                              }
                              <div className="preview-text-header">
                                <span>
                                  {(header?.length > 40 ? (header.substring(0, 37) + "...") : header) || t("common.fieldHeaders.header")}
                                </span>
                              </div>
                              {
                                (pageType === "LEADS" || pageType === "LINK") && (
                                  <div>
                                    <span className="preview-description">
                                      {(description?.length > 40 ? (description.substring(0, 37) + "...") : description) ||
                                        t("common.fieldHeaders.description")}
                                    </span>
                                  </div>
                                )
                              }
                              {
                                pageType === "STORE" && (
                                  <>
                                    {
                                      storeDateAndTime && (
                                        <div className="d-flex align-center">
                                          <AccessTimeIcon className="time-icon" />
                                          <div className="d-flex align-center store-date-time">
                                            <span className="store-date">{convertToDate(storeDateAndTime, "preview", "DD/MM")}</span>
                                            <span className="separator">|</span>
                                            <span className="store-time">{convertToDate(storeDateAndTime, "preview", "HH:MM")}</span>
                                          </div>
                                        </div>
                                      )
                                    }
                                    <div className="store-price">{t('common.currency') + getValues('price')}</div>
                                    {
                                      getValues('location') && (
                                        <div><LocationOnOutlinedIcon /> {getValues('location')}</div>
                                      )
                                    }
                                  </>
                                )
                              }
                            </div>
                            {
                              (publishDateAndTime && archiveDateAndTime && (pageType === "LINK")) ? (
                                <div className="d-flex date-text dates">
                                  <span>
                                    {convertToDate(
                                      publishDateAndTime,
                                      "preview"
                                    ) || t("common.fieldHeaders.dateFrom")}
                                  </span>
                                  <div>&nbsp; - &nbsp;</div>
                                  <span>
                                    {convertToDate(archiveDateAndTime, "preview") ||
                                      t("common.fieldHeaders.dateTo")}
                                  </span>
                                </div>
                              ) : null
                            }
                            {
                              pageType === "LINK" && (
                                <div className="w-100">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className="w-100"
                                  >
                                    {getValues('button_text') ? getValues('button_text') : t('common.buttons.buttonText')}
                                  </Button>
                                </div>
                              )
                            }
                          </div>
                        </div>
                        <div className="proportion-container">
                          <span className="text">{t('common.proportions', { x: 1.5, y: 1 })}</span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
              {(pageType === "LEADS" || pageType === "SURVEY") && (
                <>
                  {
                    pageType === "LEADS" && (
                      <div className="main-wrapper">
                        <h3>{t("benefits.create.form.details")}</h3>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={lngDirection === "rtl" ? heLocale : enLocale}
                        >
                          <Grid
                            container
                            className="create-grid"
                            justifyContent="space-between"
                            direction="column"
                          >
                            <Grid item className="grid-row" xs={6} lg={4}>
                              <Grid container>
                                <Grid item className="grid-row" xs={6}>
                                  <Controller
                                    control={control}
                                    name="date_from"
                                    render={(props: any) => (
                                      <KeyboardDatePicker
                                        // minDate={today}
                                        minDateMessage={t('errorMessages.minimumDate')}
                                        label={t("common.fieldHeaders.dateFrom")}
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="detailsFromDate-picker-inline"
                                        className="date-picker-inline"
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                        autoOk={true}
                                        onChange={(value) => {
                                          props.field.onChange(convertToDate(value));
                                        }}
                                        value={props.field.value}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item className="grid-row" xs={6}>
                                  <Controller
                                    control={control}
                                    name="date_to"
                                    render={(props): any => (
                                      <KeyboardDatePicker
                                        // minDate={today}
                                        minDateMessage={t('errorMessages.minimumDate')}
                                        label={t("common.fieldHeaders.dateTo")}
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="detailsToDate-picker-inline"
                                        className="date-picker-inline"
                                        autoOk={true}
                                        onChange={(value) => {
                                          props.field.onChange(convertToDate(value));
                                        }}
                                        value={props.field.value}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              className="grid-row"
                              xs={6} lg={4}
                              ref={detailsHeaderRef}
                            >
                              <Controller
                                control={control}
                                name="header"
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <TextField
                                      id="header"
                                      type="text"
                                      label={t("common.fieldHeaders.header")}
                                      value={value}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        onChange(e);
                                        setHeader(value);
                                        setValue('header', value);
                                      }}
                                    />
                                    <p className="field-error">
                                      {errors?.header?.message ?? ""}
                                      &nbsp;
                                    </p>
                                  </>
                                )}
                              />
                            </Grid>
                            <Grid
                              item
                              className="grid-row"
                              xs={12}
                              ref={detailsTextRef}
                            >
                              <p
                              >
                                {t("benefits.create.form.detailsText")}
                                {!getValues("is_draft") ? "*" : ""}
                              </p>
                              <Controller
                                control={control}
                                name="details_text"
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <Editor
                                      apiKey='7c1lea82c6ixszj0qqq2wtdp01kgm8syyi4eq3r85hprx9mh'
                                      value={textEditor}
                                      init={{
                                        directionality: textInputDirection(),
                                        height: 500,
                                        width: "65%",
                                        ...tinyEditorOptions,
                                        toolbar_mode: 'sliding',
                                        content_style: `body { ${lngDirection === "rtl"
                                          ? "text-align: right;"
                                          : "text-align: left;"
                                          }
                                        overflow-x: hidden; }`,
                                      }}
                                      onEditorChange={(value) => {
                                        setTextEditor(value);
                                        onChange(transformEditorText(value));
                                      }}
                                    />
                                    <p className="field-error">
                                      {errors?.details_text?.message ?? ""}
                                      &nbsp;
                                    </p>
                                  </>
                                )}
                              />
                            </Grid>
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                    )
                  }
                  <div className="main-wrapper">
                    <h3>{t("benefits.create.enterLabel")}</h3>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={lngDirection === "rtl" ? heLocale : enLocale}
                    >
                      <Grid
                        container
                        className="create-grid"
                        justifyContent="space-between"
                        direction="column"
                        ref={fieldsRef}
                      >
                        <Grid item className="grid-row" xs={6} lg={4} ref={detailsEmailRef}>
                          <p>{t("benefits.create.form.detailsEmailTitle")}</p>
                          <Controller
                            control={control}
                            name="supplier_email"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <TextField
                                  id="supplier_email"
                                  type="text"
                                  label={t("benefits.create.form.detailsEmail")}
                                  value={value}
                                  onChange={onChange}
                                />
                                <p className="field-error">
                                  {errors?.supplier_email?.message ?? ""}
                                  &nbsp;
                                </p>
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item className="grid-row" xs={6} lg={4}>
                          <Controller
                            control={control}
                            name="supplier_second_email"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <TextField
                                  id="supplier_second_email"
                                  type="text"
                                  label={t("benefits.create.form.detailsEmail")}
                                  value={value}
                                  onChange={onChange}
                                />
                                <p className="field-error">
                                  {errors?.supplier_second_email?.message ?? ""}
                                  &nbsp;
                                </p>
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item className="grid-row" xs={10} lg={12}>
                          <p>
                            {t(
                              "benefits.create.form.formBuilder.selectFieldType"
                            )}
                          </p>
                          <Grid container className="text-center">
                            <Grid
                              item
                              className="grid-row box"
                              xs={2} lg={1}
                              onClick={() => addForm("textfield")}
                            >
                              <img src={TextFieldIcon} alt="text-field" />
                              <div>
                                {t("benefits.create.form.formBuilder.textfield")}
                              </div>
                            </Grid>

                            {
                              pageType === "LEADS" && (
                                <Grid
                                  item
                                  className="grid-row box"
                                  xs={2} lg={1}
                                  onClick={() => addForm("dropdown")}
                                >
                                  <img src={DropdownIcon} alt="dropdown" />
                                  <div>
                                    {t("benefits.create.form.formBuilder.dropdown")}
                                  </div>
                                </Grid>
                              )
                            }
                            <Grid
                              item
                              className="grid-row box"
                              xs={2} lg={1}
                              onClick={() => addForm("checkbox")}
                            >
                              <img src={CheckboxIcon} alt="checkbox" />
                              <div>
                                {t("benefits.create.form.formBuilder.checkbox")}
                              </div>
                            </Grid>
                            <Grid
                              item
                              className="grid-row box"
                              xs={2} lg={1}
                              onClick={() => addForm("radio")}
                            >
                              <img src={RadioButtonIcon} alt="radio-button" />
                              <div>
                                {t(
                                  "benefits.create.form.formBuilder.radio"
                                )}
                              </div>
                            </Grid>
                          </Grid>
                          {/* @ts-ignore */}
                          <p className="field-error">
                            {" "}
                            {errors?.fields?.message ?? ""}&nbsp;{" "}
                          </p>
                          <Grid item className="grid-row" xs={6} lg={4}>
                            {formBuilder.map((el, formIndex) => {
                              if (el.type === "textfield") {
                                return (
                                  <>
                                    <Grid
                                      key={formIndex}
                                      container
                                      className="container-box"
                                    >
                                      <Grid item xs={12}>
                                        {t(`benefits.create.form.formBuilder.${el.type}`)}
                                      </Grid>
                                      <Grid item xs={11}>
                                        <TextField
                                          label={el.label}
                                          placeholder={t(
                                            "benefits.create.enterLabel"
                                          )}
                                          value={el.label}
                                          onChange={(e) =>
                                            handleLabelChangeOnForm(
                                              formIndex,
                                              e.target.value
                                            )
                                          }
                                          InputLabelProps={{ shrink: true }}
                                        />
                                        <p className="field-error">
                                          {!el.label.length
                                            ? t(
                                              "benefits.validationMessages.fillLabel"
                                            )
                                            : (isCharOfLabelIsNumber(el.label)
                                              ? t(
                                                "benefits.validationMessages.labelCantHaveNumberAsFirstChar"
                                              )
                                              : "")}
                                          &nbsp;
                                        </p>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <IconButton
                                          aria-label="delete selected item"
                                          className="hover-item"
                                          onClick={() => deleteForm(formIndex)}
                                        >
                                          <IconComponent icon={DeleteIcon} />
                                        </IconButton>
                                      </Grid>
                                      {
                                        pageType === "SURVEY" && (
                                          <Grid item xs={12}>
                                            <FormControlLabel
                                              className="form-control-label"
                                              control={
                                                <Checkbox
                                                  color="primary"
                                                  checked={el.is_required || false}
                                                  onClick={() =>
                                                    handleIsRequiredChangeOnForm(formIndex)
                                                  }
                                                />
                                              }
                                              label={t("benefits.create.form.formBuilder.isRequired")}
                                            />
                                          </Grid>
                                        )
                                      }
                                    </Grid>
                                  </>
                                );
                              }
                              if (
                                el.type === "radio"
                                || el.type === "dropdown"
                                || el.type === "checkbox"
                              ) {
                                return (
                                  <>
                                    <Grid
                                      key={formIndex}
                                      container
                                      className="container-box"
                                      justifyContent="space-between"
                                    >
                                      <Grid item xs={12}>
                                        {t(`benefits.create.form.formBuilder.${el.type}`)}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={11}
                                        className="container-box-item"
                                      >
                                        <TextField
                                          label={el.label}
                                          placeholder={t(
                                            "benefits.create.enterLabel"
                                          )}
                                          value={el.label}
                                          onChange={(e) =>
                                            handleLabelChangeOnForm(
                                              formIndex,
                                              e.target.value
                                            )
                                          }
                                          InputLabelProps={{ shrink: true }}
                                        />
                                        <p className="field-error">
                                          {!el.label.length
                                            ? t(
                                              "benefits.validationMessages.fillLabel"
                                            )
                                            : (isCharOfLabelIsNumber(el.label)
                                              ? t(
                                                "benefits.validationMessages.labelCantHaveNumberAsFirstChar"
                                              )
                                              : "")}
                                          &nbsp;
                                        </p>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <IconButton
                                          aria-label="delete selected item"
                                          className="hover-item"
                                          onClick={() => deleteForm(formIndex)}
                                        >
                                          <IconComponent icon={DeleteIcon} />
                                        </IconButton>
                                      </Grid>
                                      {
                                        pageType === "SURVEY" && (
                                          <Grid item xs={12}>
                                            <FormControlLabel
                                              className="form-control-label"
                                              control={
                                                <Checkbox
                                                  color="primary"
                                                  checked={el.is_required || false}
                                                  onClick={() =>
                                                    handleIsRequiredChangeOnForm(formIndex)
                                                  }
                                                />
                                              }
                                              label={t("benefits.create.form.formBuilder.isRequired")}
                                            />
                                          </Grid>
                                        )
                                      }
                                      {el.options.map((option, optIndex) => {
                                        return (
                                          <>
                                            <Grid
                                              container
                                              key={optIndex}
                                            >
                                              <Grid
                                                item
                                                xs={6}
                                                className="container-box-item"
                                              >
                                                <TextField
                                                  label={t(
                                                    "benefits.create.form.formBuilder.value"
                                                  )}
                                                  placeholder={t(
                                                    "benefits.create.form.formBuilder.enterValue"
                                                  )}
                                                  value={option.value}
                                                  onChange={(e) =>
                                                    handleOptionChangeOnForm(
                                                      formIndex,
                                                      "value",
                                                      optIndex,
                                                      e.target.value
                                                    )
                                                  }
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                />
                                                <p className="field-error">
                                                  {!option.value
                                                    ? t(
                                                      "benefits.validationMessages.fillValue"
                                                    )
                                                    : ""}
                                                  &nbsp;
                                                </p>
                                              </Grid>
                                              <Grid item xs={1}>
                                                <IconButton
                                                  aria-label="delete selected item"
                                                  className="hover-item"
                                                  onClick={() =>
                                                    deleteFormOption(
                                                      formIndex,
                                                      optIndex
                                                    )
                                                  }
                                                >
                                                  <IconComponent
                                                    icon={DeleteIcon}
                                                  />
                                                </IconButton>
                                              </Grid>
                                            </Grid>
                                          </>
                                        );
                                      })}
                                      <Grid
                                        item
                                        xs={6} lg={4}
                                        className="add-option-button"
                                        onClick={() => addFormOption(formIndex)}
                                      >
                                        <AddIcon />
                                        <span>
                                          {t(
                                            "benefits.create.form.formBuilder.addOption"
                                          )}
                                        </span>
                                      </Grid>
                                    </Grid>
                                  </>
                                );
                              }
                              return null;
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </div>
                </>
              )}
              {pageType === "LINK" && (
                <div className="main-wrapper">
                  <h3>{t("benefits.create.form.details")}</h3>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={lngDirection === "rtl" ? heLocale : enLocale}
                  >
                    <Grid
                      container
                      className="create-grid"
                      justifyContent="space-between"
                      direction="column"
                    >
                      <p>{t("benefits.create.form.addButton")}</p>
                      <Grid item xs={6} lg={4}>
                        <Grid container>
                          <Grid item xs={3} ref={buttonRef}>
                            <Controller
                              control={control}
                              name="button_text"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <TextField
                                    id="button_text"
                                    label={t("benefits.create.form.buttonText")}
                                    value={value}
                                    onChange={onChange}
                                  />
                                  <p className="field-error">
                                    {errors?.button_text?.message ?? ""}&nbsp;
                                  </p>
                                </>
                              )}
                            />
                          </Grid>
                          <div className="small-separator" />
                          <Grid item xs={8} ref={linkRef}>
                            <Controller
                              control={control}
                              name="link"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <TextField
                                    id="link"
                                    label={t("benefits.create.form.link")}
                                    value={value}
                                    onChange={onChange}
                                  />
                                  <p className="field-error">
                                    {errors?.link?.message ?? ""}&nbsp;
                                  </p>
                                </>
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </div>
              )}
              {pageType === "STORE" && (
                <div className="main-wrapper">
                  <h3>{t("benefits.create.form.details")}</h3>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={lngDirection === "rtl" ? heLocale : enLocale}
                  >
                    <Grid
                      container
                      className="create-grid"
                      justifyContent="space-between"
                      direction="column"
                    >
                      <Grid item className="grid-row" xs={6} lg={4}>
                        <Grid container>
                          <Grid item className="grid-row" xs={8}>
                            <KeyboardDatePicker
                              // disablePast
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              id="storeDate-picker-inline"
                              className="date-picker-inline"
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              onChange={(value: any) => {
                                changeDateOnly(
                                  value,
                                  storeHourAndMinutes,
                                  setStoreDateAndTime
                                );
                              }}
                              value={convertToDate(storeDateAndTime)}
                              autoOk={true}
                            />
                          </Grid>
                          <Grid item className="grid-row" xs={4}>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              okLabel={t("timePicker.confirm")}
                              cancelLabel={t("timePicker.cancel")}
                              value={storeDateAndTime}
                              onChange={(value: any) => {
                                changeTimeOnly(
                                  value,
                                  setStoreDateAndTime,
                                  setStoreHourAndMinutes
                                );
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "Hour",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="grid-row" xs={6} lg={4} ref={locationRef}>
                        <Controller
                          control={control}
                          name="location"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <TextField
                                id="location"
                                label={t("common.fieldHeaders.location")}
                                value={value}
                                onChange={onChange}
                              />
                              <p className="field-error">
                                {errors?.location?.message ?? ""}&nbsp;
                              </p>
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item className="grid-row" xs={6} lg={6} ref={ticketsRef}>
                        <Grid container justifyContent="space-between">
                          <Grid item className="grid-row" xs={4}>
                            <Controller
                              control={control}
                              name="number_of_tickets"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <TextField
                                    id="number_of_tickets"
                                    className="number_of_tickets"
                                    type="number"
                                    label={t(
                                      "common.fieldHeaders.numberOfTickets"
                                    )}
                                    onChange={(e: any) => {
                                      if (e.target.value) {
                                        onChange(e);
                                      } else {
                                        onChange(0);
                                      }
                                    }}
                                  />
                                  <p className="field-error">
                                    {errors?.number_of_tickets?.message ?? ""}
                                    &nbsp;
                                  </p>
                                </>
                              )}
                            />
                          </Grid>
                          <Grid item className="grid-row" xs={4} ref={priceRef}>
                            <Controller
                              control={control}
                              name="price"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <TextField
                                    id="price"
                                    className="price-field"
                                    type="number"
                                    label={`${t(
                                      "common.fieldHeaders.price"
                                    )} - ${t("common.israeliCurrency")}`} //currency
                                    onChange={(e: any) => {
                                      if (e.target.value) {
                                        onChange(e);
                                      } else {
                                        onChange(0);
                                      }
                                    }}
                                  />
                                  <p className="field-error">
                                    {errors?.price?.message ?? ""}&nbsp;
                                  </p>
                                </>
                              )}
                            />
                          </Grid>
                          <Grid item className="grid-row" xs={4} ref={maxPurchasesRef}>
                            <Controller
                              control={control}
                              name="max_purchases"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <TextField
                                    id="max_purchases"
                                    className="number_of_tickets"
                                    type="number"
                                    label={t(
                                      "common.fieldHeaders.maxPurchases"
                                    )}
                                    onChange={(e: any) => {
                                      const value = e.target.value;
                                      if (value) {
                                        onChange(e);
                                      } else {
                                        onChange(0);
                                      }
                                    }}
                                  />
                                  <p className="field-error">
                                    {errors?.max_purchases?.message ?? ""}
                                    &nbsp;
                                  </p>
                                </>
                              )}
                            />
                          </Grid>

                        </Grid>
                      </Grid>
                      <Grid
                        item
                        className="grid-row"
                        xs={6} lg={4}
                        ref={detailsHeaderRef}
                      >
                        <Controller
                          control={control}
                          name="header"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <TextField
                                id="header"
                                label={t("common.fieldHeaders.header")}
                                value={value}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  onChange(e);
                                  setHeader(value);
                                  setValue('header', value);
                                }}
                              />
                              <p className="field-error">
                                {errors?.header?.message ?? ""}&nbsp;
                              </p>
                            </>
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        className="grid-row"
                        xs={12}
                        ref={detailsTextRef}
                      >
                        <p>
                          {t(
                            "articles.allArticles.create.form.preview.detailsText"
                          )}
                          {!getValues("is_draft") ? "*" : ""}
                        </p>
                        <Controller
                          control={control}
                          name="details_text"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <Editor
                                apiKey='7c1lea82c6ixszj0qqq2wtdp01kgm8syyi4eq3r85hprx9mh'
                                value={textEditor}
                                init={{
                                  directionality: textInputDirection(),
                                  height: 500,
                                  width: "65%",
                                  zIndex: 1,
                                  ...tinyEditorOptions,
                                  toolbar_mode: 'sliding',
                                  content_style: `body { ${lngDirection === "rtl"
                                    ? "text-align: right;"
                                    : "text-align: left;"
                                    }
                                    overflow-x: hidden; }`,
                                }}
                                onEditorChange={(value) => {
                                  setTextEditor(value);
                                  onChange(transformEditorText(value));
                                }}
                              />
                              <p className="field-error">
                                {errors?.details_text?.message ?? ""}&nbsp;
                              </p>
                            </>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </div>
              )}
              <Grid item xs={12}>
                <FormHelperText error className="error-text">
                  {submitError ? stringToCapital(submitError) : ""}&nbsp;
                </FormHelperText>
              </Grid>
              <div className="submit-buttons">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    setValue("is_draft", false);
                    setUnsavedChanges(false);
                    setTimeout(() => {
                      scrollToError();
                    });
                  }}
                  disabled={!categories.length}
                >
                  {t("common.buttons.previewAndPublish")}
                </Button>
                <Button
                  className="link-button-container"
                  type="submit"
                  onClick={() => {
                    setValue("is_draft", true);
                    setUnsavedChanges(false);
                    setTimeout(() => {
                      scrollToError();
                    });
                  }}
                  disabled={!categories.length}
                >
                  <Link className="link-btn">
                    {t("common.buttons.saveAsDraft")}
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        </form>
        <PreviewModal
          className="create-benefit"
          openModal={openModal}
          handleClose={handleCloseModal}
          content={
            <>
              <div className="preview-image-container">
                <div className={`preview-image`}>
                  <img src={showImage ? croppedImage : fileUpload.url} alt={fileUpload?.name ?? ""} />
                </div>
                <div className="preview-text-container">
                  <div className="preview-text">
                    <div>
                      {
                        (pageType === "STORE" || pageType === "SURVEY") && (
                          <div className="preview-category">
                            {getValues('category_id') ? getElementFromArrayObject('id', 'categoryName', getValues('category_id'), categories) : t('common.fieldHeaders.category')}
                          </div>
                        )
                      }
                      <div className="preview-text-header">
                        <span>
                          {(header?.length > 40 ? (header.substring(0, 37) + "...") : header) || t("common.fieldHeaders.header")}
                        </span>
                      </div>
                      {
                        (pageType === "LEADS" || pageType === "LINK") && (
                          <div>
                            <span className="preview-description">
                              {(description?.length > 40 ? (description.substring(0, 37) + "...") : description) ||
                                t("common.fieldHeaders.description")}
                            </span>
                          </div>
                        )
                      }
                      {
                        pageType === "STORE" && (
                          <>
                            {
                              storeDateAndTime && (
                                <div className="d-flex align-center">
                                  <AccessTimeIcon className="time-icon" />
                                  <div className="d-flex align-center store-date-time">
                                    <span className="store-date">{convertToDate(storeDateAndTime, "preview", "DD/MM")}</span>
                                    <span className="separator">|</span>
                                    <span className="store-time">{convertToDate(storeDateAndTime, "preview", "HH:MM")}</span>
                                  </div>
                                </div>
                              )
                            }
                            <div className="store-price">{t('common.currency') + getValues('price')}</div>
                            {
                              getValues('location') && (
                                <div className="d-flex align-center">
                                  <span className="location-icon"><LocationOnOutlinedIcon /></span>
                                  <span>{getValues('location')}</span>
                                </div>
                              )
                            }
                          </>
                        )
                      }
                    </div>
                    {
                      (publishDateAndTime && archiveDateAndTime && (pageType === "LINK")) ? (
                        <div className="d-flex date-text dates">
                          <span>
                            {convertToDate(
                              publishDateAndTime,
                              "preview"
                            ) || t("common.fieldHeaders.dateFrom")}
                          </span>
                          <div>&nbsp; - &nbsp;</div>
                          <span>
                            {convertToDate(archiveDateAndTime, "preview") ||
                              t("common.fieldHeaders.dateTo")}
                          </span>
                        </div>
                      ) : null
                    }
                    {
                      pageType === "LINK" && (
                        <div className="w-100">
                          <Button
                            variant="contained"
                            color="primary"
                            className="w-100"
                            size="small"
                          >
                            {getValues('button_text') ? getValues('button_text') : t('common.buttons.buttonText')}
                          </Button>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </>
          }
        ></PreviewModal>
        <NotificationModal
          openModal={openNotificationModal}
          buttonText={t("common.buttons.close")}
          handleClose={handleNotificationClose}
          handleButtonClick={handleNotificationClose}
          message={notificationMessage}
        />
        {innerLoading ? <Spinner text={t("common.uploading")} /> : null}
      </div >
    </>
  );
};

export default observer(NewSale);
