import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import ImagePlaceholder from "../../../../assets/images/image-placeholder.png";
import UploadFile from "../../../../components/UploadFile/UploadFile";
import "../Create/style.scss";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { Editor } from "@tinymce/tinymce-react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FIELDS, VALIDATIONS } from "./articleFields";
import { flowResult } from "mobx";
import { useStore } from "../../../../store/StoreContext";
import { useHistory, useParams } from "react-router";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";
import UserTypeEnum from "../../../../models/UserType";
import ProceedImage from "../../../../shared/utils/proceedImage";
import Spinner from "../../../../components/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import heLocale from "date-fns/locale/he";
import enLocale from "date-fns/locale/en-US";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import FormatSizeOutlinedIcon from '@material-ui/icons/FormatSizeOutlined';
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../../shared/utils/cropImage";
import { convertLocalToZuluTime, convertZuluToLocalTime, convertToDate, formatAppearsIds } from "../../../../shared/utils/sharedFunctions";
import { tinyEditorOptions } from "../../../../shared/utils/sharedOptions";
import BackIcon from "../../../../assets/icons/back-arrow-reverse.svg"
import IconComponent from "../../../../shared/components/IconComponent/IconComponent";
import MultiSelect from "../../../../components/MultiSelect/MultiSelect";

const ViewArticle = () => {
  const [isLoading, setIsLoading] = useState(true);
  const params: any = useParams();
  const id = params.id;
  const areFieldsDisabled = params.type === "view";
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const {
    articleStore,
    contentStore,
    groupStore,
    departmentStore,
    userStore,
    actionStore,
    languageStore,
  } = useStore();
  const [fileUpload, setFileUpload] = useState({
    lastModified: 0,
    lastModifiedDate: new Date(),
    name: "",
    size: 0,
    type: "",
    webkitRelativePath: "",
    url: ImagePlaceholder,
  });
  const [showImage, setShowImage] = useState(false);
  const [template, setTemplate] = useState(1);
  const [details, setDetails] = useState("write");
  const [isPushNotification, setIsPushNotification] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [innerLoading, setInnerLoading] = useState(false);
  const [header, setHeader] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [textEditor, setTextEditor] = useState("");
  const [isNewPictureUploaded, setIsNewPictureUploaded] = useState(false);
  const [previewImageWidth, setPreviewImageWidth] = useState(0);
  const [previewImageHeight, setPreviewImageHeight] = useState(0);
  const [previewAndPublishImageHeight, setPreviewAndPublishImageHeight] = useState(0);
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
  const [isSelectAllSelectedSecondary, setIsSelectAllSelectedSecondary] = useState(true);

  // GET DATA
  const [categories, setCategories] = useState([
    {
      id: "",
      contentName: "",
    },
  ]);
  const [groups, setGroups] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [secondaryDepartments, setSecondaryDepartments] = useState<any>([]);

  const [roleType, setRoleType] = useState(null);

  // Preview Modal
  const [openModal, setOpenModal] = useState(false);
  // Notification Modal
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const capture = (file) => {
    if (!videoData) {
      return;
    }
    let fileReader: any = new FileReader();

    fileReader.onload = () => {
      let blob = new Blob([fileReader.result], { type: file.type });
      let url = URL.createObjectURL(blob);
      let video = document.createElement("video");
      const timeupdate = () => {
        if (snapImage()) {
          video.removeEventListener("timeupdate", timeupdate);
          video.pause();
        }
      };
      video.addEventListener("loadeddata", function () {
        if (snapImage()) {
          video.removeEventListener("timeupdate", timeupdate);
        }
      });
      const snapImage = () => {
        let canvas: any = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, canvas.width, canvas.height);
        let image = canvas.toDataURL();
        // let success = image.length > 100000;
        // if (success) {
        // const imageBase64: any = await ProceedImage.toBase64(image);
        setShowImage(true);
        setFileUpload({ ...fileUpload, url: image });
        setValue("image", image);
        // document.getElementsByTagName('div')[0].appendChild(img);
        URL.revokeObjectURL(url);
        // }
        return true;
      };
      video.addEventListener("timeupdate", timeupdate);
      video.preload = "metadata";
      video.src = url;
      // Load video in Safari / IE11
      video.muted = true;
      video.playsInline = true;
      video.play();
    };
    fileReader.readAsArrayBuffer(file);
  };

  const [isRedirect, setIsRedirect] = useState(true);
  const [videoData, setVideoData] = useState({});
  const [videoURL, setVideoURL] = useState("");

  const handleFileUpload = async (data) => {
    if (data) {
      setInnerLoading(true);
      let image: any = "";
      if (data.type.indexOf("image") !== -1) {
        image = await ProceedImage.toBase64(data);
        setVideoData({});
        setVideoURL("");
        setValue('thumbnail', null);
      }
      if (data.type.indexOf("video") !== -1) {
        const formData = new FormData();
        formData.append("file", data);
        image = await flowResult(actionStore.uploadFile(formData)).then(
          (response: any) => {
            setIsLoading(false);
            return response;
          }
        );
        if (image) {
          setVideoData(data);
          setVideoURL(image);
        }

      }
      setShowImage(true);
      setFileUpload({ ...data, url: image });
      setValue("image", image);
      //Initiate the FileReader object.
      const reader = new FileReader();
      //Read the contents of Image File.
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        let image: any = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          const height: number = Number(this.height);
          const width: number = Number(this.width);
          if (template !== 2) {
            if (height > width * 1.49) {
              const zoom = (height / width * 0.67);
              setZoom(zoom);
              setMaxZoom(zoom * 4);
            } else {
              setZoom(1);
              setMaxZoom(4);
            }
          } else {
            if (width > height) {
              let zoom = (width / height * 0.654);
              zoom = zoom >= 1 ? zoom : 1;
              setZoom(zoom);
              setMaxZoom(zoom * 4);
            } else {
              setZoom(1);
              setMaxZoom(4);
            }
          }
          setTimeout(() => {
            const cropperImage: any = document.querySelector('.reactEasyCrop_Image');
            if (cropperImage.className.indexOf('reactEasyCrop_Cover_Vertical') > -1) {
              cropperImage.style.height = '100.1%';
            } else if (cropperImage.className.indexOf('reactEasyCrop_Cover_Horizontal') > -1) {
              cropperImage.style.width = '100.1%';
            }
          })
        };
      };
      reader.readAsDataURL(data);
      setIsNewPictureUploaded(true);
      setInnerLoading(false);
    }
  };

  useEffect(() => {
    if (videoURL) capture(videoData);
  }, [videoURL]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(VALIDATIONS),
    mode: "onChange",
    defaultValues: {
      template: 1,
      header: "",
      category_id: "",
      image: "",
      appears: [],
      is_push_notification: false,
      push_notification_text: "",
      publish_date: convertToDate(new Date()),
      archive_date: convertToDate(
        new Date(new Date().setDate(new Date().getDate() + 7))
      ),
      details: "write",
      external_link: "",
      link_description: "",
      details_header: "",
      writer: "",
      text: "",
      is_draft: false,
    },
  } as any);

  const [submitError, setSubmitError] = useState("");

  useEffect(() => {
    Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
  }, [register]);

  const saveArticle = async (data) => {
    setIsLoading(true);
    setSubmitError("");
    if (isNewPictureUploaded) {
      if (data.template !== 3) {
        let image = data.image;
        if (videoURL) {
          image = data.thumbnail
        }
        const formData = new FormData();
        formData.append("file", image);
        const croppedImage = await flowResult(actionStore.uploadFile(formData)).then(
          (response: any) => {
            return response;
          }
        );
        if (videoURL) {
          data = { ...data, thumbnail: croppedImage }
        } else {
          data = { ...data, image: croppedImage }
        }
      }
    }
    await flowResult(articleStore.update(data, id)).then((response: any) => {
      setIsLoading(false);
      if (!response?.success) {
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0"),
          false
        );
        return;
      }
      handleNotificationOpen(
        response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"),
        true
      );
    });
  };

  const transformToData = async (objectData) => {
    let croppedImage = objectData.image;
    if (isNewPictureUploaded) {
      if (objectData.template !== 3) {
        croppedImage = await cropImage(croppedAreaPixels);
      }
    }
    const data = {
      template: objectData.template,
      header: objectData.header,
      category_id: objectData.category_id,
      image:
        objectData.template !== 3 ? (videoURL ? videoURL : croppedImage) : "",
      appears: objectData.appears.filter((el) => el !== 'select-all'),
      is_push_notification: objectData.is_push_notification,
      push_notification_text: objectData.push_notification_text,
      publish_date: convertLocalToZuluTime(publishDateAndTime),
      archive_date: convertLocalToZuluTime(archiveDateAndTime),
      details: {
        external_link: objectData.external_link,
        link_description: objectData.link_description,
        header: objectData.header,
        writer: objectData.writer,
        text: textEditor,
      },
      is_draft: false,
    };
    if ((videoURL && objectData.template !== 3) || objectData.thumbnail) {
      let thumbnail = objectData.thumbnail;
      if (isNewPictureUploaded) {
        thumbnail = croppedImage
      }
      data["thumbnail"] = thumbnail;
    } else {
      data["thumbnail"] = null;
    }
    return data;
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmitCheck = async (objectData) => {
    if (areFieldsDisabled) {
      setOpenModal(true);
      return;
    }
    if (publishInvalidDate || archiveInvalidDate) {
      return;
    }
    setIsLoading(true);
    let data = await transformToData(objectData);
    setIsNewPictureUploaded(false);
    if (!objectData.push_notification_text)
      delete data["push_notification_text"];
    if (details === "write") {
      delete data["external_link"];
      delete data["link_description"];
    } else {
      delete data["details_header"];
      delete data["writer"];
      delete data["text"];
    }
    setSubmitError("");
    saveArticle(data);
  };

  const transformEditorText = (text) => {
    if (text && text.length > 0) {
      return text
        .replace(/(<([^>]+)>)/gi, "")
        // eslint-disable-next-line no-useless-escape
        .replace(/\&nbsp;/g, "")
        .trim();
    }
    return text;
  };

  const handleNotificationOpen = (message = "", isRedirect) => {
    setNotificationMessage(message);
    setIsRedirect(isRedirect);
    setOpenNotificationModal(true);
  };

  const handleNotificationClose = () => {
    setOpenNotificationModal(false);
    if (isRedirect) history.push("/mobile/articles/list");
  };

  useEffect(() => {
    setCategories(contentStore.getStoredContents());
    const groups = groupStore.getStoredGroups();
    const departments = departmentStore.getStoredDepartments();
    const secondaryDepartments = departmentStore.getStoredSecondaryDepartments();
    setGroups(groups);
    setDepartments(departments);
    setSecondaryDepartments(secondaryDepartments);
    const roleType = userStore.getRoleType();
    setRoleType(roleType);
    flowResult(actionStore.getPathItems(`article/${id}`)).then(
      (response: any) => {
        if (!response?.success) {
          handleNotificationOpen(
            response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"),
            true
          );
          setIsLoading(false);
          return;
        }
        const article = response.article;
        let appears = formatAppearsIds(article?.appears, roleType);
        appears = appears.filter((el, index) => appears.indexOf(el) === index);
        const groupOrDepartment = roleType === UserTypeEnum.owner ? groups : [...departments, ...secondaryDepartments];
        if(roleType !== UserTypeEnum.owner) {
          setIsSelectAllSelected(appears.filter(depID => departments.some(dep => dep.id === depID)).length === departments.length);
          setIsSelectAllSelectedSecondary(appears.filter(depID => secondaryDepartments.some(dep => dep.id === depID)).length === secondaryDepartments.length);
        } else {
          setIsSelectAllSelected(appears.length === groupOrDepartment.length);
        }
        setValue("appears", appears.length === groupOrDepartment.length ? [...appears, 'select-all'] : appears);

        setValue("template", article?.template ?? 1);
        setTemplate(article?.template ?? 1);
        setValue("header", article?.header ?? "");
        setHeader(article?.header ?? "");
        setValue("category_id", article?.category?.id ?? "");
        setSelectedCategory(article?.category?.contentName ?? "");
        setValue("image", article?.image ?? "");
        if (article?.thumbnail) {
          setValue("thumbnail", article?.thumbnail ?? "");
          setFileUpload({
            ...fileUpload,
            url: article?.thumbnail ?? ImagePlaceholder,
          });
          setShowImage(!!article?.thumbnail);
        } else {
          setValue("image", article?.image ?? "");
          setFileUpload({
            ...fileUpload,
            url: article?.image ?? ImagePlaceholder,
          });
          setShowImage(!!article?.image);
        }
        setValue("is_push_notification", article?.isPushNotification ?? false);
        setIsPushNotification(article?.isPushNotification ?? false);
        setValue("push_notification_text", article?.pushNotificationText ?? "");
        const publishDate = convertZuluToLocalTime(article.publishDate);
        const archiveDate = convertZuluToLocalTime(article.archiveDate);
        setValue("publish_date", convertToDate(new Date(publishDate)));
        setPublishDateAndTime(new Date(publishDate));
        setPublishHourAndMinutes({
          hour: new Date(publishDate).getHours(),
          minutes: new Date(publishDate).getMinutes(),
        });
        setValue("archive_date", convertToDate(new Date(archiveDate)));
        setArchiveDateAndTime(new Date(archiveDate));
        setArchiveHourAndMinutes({
          hour: new Date(archiveDate).getHours(),
          minutes: new Date(archiveDate).getMinutes(),
        });
        setValue(
          "details",
          article?.details?.external_link ? "externalLink" : "write"
        );
        setDetails(article?.details?.external_link ? "externalLink" : "write");
        setValue("external_link", article?.details?.external_link ?? "");
        setValue("link_description", article?.details?.link_description ?? "");
        setShortDescription(article?.details?.link_description ?? "");
        setValue("details_header", article?.details?.header ?? "");
        setValue("writer", article?.details?.writer ?? "");
        setValue("text", transformEditorText(article?.details?.text) ?? "");
        setTextEditor(article?.details?.text ?? "");
        setIsLoading(false);
      }
    );
  }, []);

  // Detecting Language Switch
  const [lngDirection, setLngDirection] = useState("");

  useEffect(() => {
    const dir = i18n.dir();
    setLngDirection(dir);
  }, [languageStore.switchLng]);

  const textInputDirection = () => {
    if (lngDirection === "rtl") return "rtl";
    if (lngDirection === "ltr") return "ltr";
  };

  // Ref
  const headerRef: any = useRef(null);
  const categoryRef: any = useRef(null);
  const imageRef: any = useRef(null);
  const appearsRef: any = useRef(null);
  const pushTextRef: any = useRef(null);
  const externalRef: any = useRef(null);
  const detailsHeaderRef: any = useRef(null);
  const writerRef: any = useRef(null);
  const textRef: any = useRef(null);
  const publishDateRef: any = useRef(null);
  const archiveDateRef: any = useRef(null);

  const scrollToError = () => {
    if (errors?.header?.message) {
      headerRef.current.scrollIntoView();
    } else if (errors?.category_id?.message) {
      categoryRef.current.scrollIntoView();
    } else if (errors?.image?.message && !isNewPictureUploaded && !fileUpload.url) {
      imageRef.current.scrollIntoView();
      // @ts-ignore
    } else if (errors?.appears?.message) {
      appearsRef.current.scrollIntoView();
    } else if (errors?.push_notification_text?.message) {
      pushTextRef.current.scrollIntoView();
    } else if (errors?.external_link?.message) {
      externalRef.current.scrollIntoView();
    } else if (errors?.link_description?.message) {
      externalRef.current.scrollIntoView();
    } else if (errors?.writer?.message) {
      writerRef.current.scrollIntoView();
    } else if (errors?.text?.message) {
      textRef.current.scrollIntoView();
    } else if (publishInvalidDate) {
      publishDateRef.current.scrollIntoView();
    } else if (archiveInvalidDate) {
      archiveDateRef.current.scrollIntoView();
    }
  };

  const [publishDateAndTime, setPublishDateAndTime] = useState(new Date());
  const [publishHourAndMinutes, setPublishHourAndMinutes] = useState({
    hour: 0,
    minutes: 0,
  });

  const [archiveDateAndTime, setArchiveDateAndTime] = useState(new Date());
  const [archiveHourAndMinutes, setArchiveHourAndMinutes] = useState({
    hour: 0,
    minutes: 0,
  });

  const changeDateOnly = (
    time,
    hourAndMinutes,
    setDateAndTime,
    setInvalidDate,
    type = ""
  ) => {
    if (time instanceof Date) {
      const hours = hourAndMinutes.hour;
      const minutes = hourAndMinutes.minutes;
      if (!isNaN(hours) && !isNaN(minutes))
        setDateAndTime((date: any) => {
          if (date) {
            date = new Date(time);
            date.setHours(hours, minutes);
            const newDate = new Date(date);
            return newDate;
          }
          return new Date();
        });
    }
  };

  const changeTimeOnly = (
    time,
    setDateAndTime,
    setHourAndMinutes,
    setInvalidDate,
    type = ""
  ) => {
    if (time instanceof Date) {
      if (!isNaN(time.getHours()) && !isNaN(time.getMinutes()))
        setDateAndTime((date: any) => {
          if (date) {
            date.setHours(time.getHours(), time.getMinutes());
            const newDate = new Date(date);
            setHourAndMinutes({
              hour: time.getHours(),
              minutes: time.getMinutes(),
            });
            return newDate;
          }
          return new Date();
        });
    }
  };

  const [publishInvalidDate, setPublishInvalidDate] = useState(false);
  const [archiveInvalidDate, setArchiveInvalidDate] = useState(false);

  const transformText = (children, len = 0, maxLen, maxRows) => {
    let charCount = len;
    children = [...children];
    children.forEach((child, index) => {
      if (charCount >= maxLen || index > maxRows) {
        child.remove();
        return;
      }
      if ((charCount + child.innerText.length) >= maxLen) {
        child.innerText = child.innerText.substring(0, maxLen - charCount) + "...";
      }
      charCount += child.innerText.length;
      child.style.fontSize = "1.1em";
      if (child.style.backgroundColor) {
        child.style.backgroundColor = "unset";
      }
      let innerChildren = child.children;
      if (innerChildren.length) {
        transformText(innerChildren, charCount, maxLen, maxRows);
      }
    });
  }

  const displayHTML = (value, maxChar = 160, maxRows = 3) => {
    const div = document.createElement("div");
    div.innerHTML = value;
    let children: any = div.children;
    transformText(children, 0, maxChar, maxRows);
    const html = { __html: div.innerHTML };
    return <div className="dangerous-html" dangerouslySetInnerHTML={html} />;
  };

  const displayHTMLPreview = (value) => {
    const div = document.createElement("div");
    div.innerHTML = value;
    let children: any = div.children;
    children = [...children];
    let len = 0;
    const maxLen = 400;
    children.forEach((child, index) => {
      if (len >= maxLen || index > 3) {
        child.remove();
        return;
      }
      if ((len + child.innerText.length) >= maxLen) {
        child.innerText = child.innerText.substring(0, maxLen - len) + "...";
      }
      len += child.innerText.length;
      if (child.style.fontSize) {
        child.style.fontSize =
          Number(child.style.fontSize.replace("px", "") * 1).toFixed(2) +
          "px";
      } else {
        child.style.fontSize = "14px";
      }
      if (child.style.backgroundColor) {
        child.style.backgroundColor = "unset";
      }
    });
    const html = { __html: div.innerHTML };
    return <div className="dangerous-html" dangerouslySetInnerHTML={html} />;
  };

  // Cropper
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [maxZoom, setMaxZoom] = useState(4);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async (croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(
      fileUpload?.url,
      croppedAreaPixels,
      true
    );
    return croppedImage;
  };

  useEffect(() => {
    const previewElement: any = document.querySelector(".preview-image");
    if (previewElement) {
      setPreviewImageWidth(previewElement.offsetWidth);
      setPreviewImageHeight(previewElement.offsetWidth * (1.49)); // 0.65
    }

  }, [isLoading === false, template]);

  useEffect(() => {
    const previewElement: any = document.querySelector(".preview-and-publish .image-container");
    if (previewElement) {
      setPreviewAndPublishImageHeight(previewElement.offsetWidth * (0.49)); // 0.65
    }

  }, [openModal === true]);

  const onDepartmentSelectChange = (data, isSelectAll = false) => {
    const secondaries = getValues('appears').filter(depID => secondaryDepartments.some(dep => dep.id === depID))
    setValue('appears', [...data, ...secondaries]);
    setIsSelectAllSelected(isSelectAll)
  }
  const onSecondaryDepartmentSelectChange = (data, isSelectAll = false) => {
    const deps = getValues('appears').filter(depID => departments.some(dep => dep.id === depID))
    setValue('appears', [...data, ...deps]);
    setIsSelectAllSelectedSecondary(isSelectAll);
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="create-mobile-article">
      {
        !openModal ? (
          <div className="main-wrapper">
            <div className="heading-container">
              <div className="back-icon" onClick={() => history.push("/mobile/articles/list")}>
                <IconComponent icon={BackIcon} />
              </div>
              <h2 className="page-heading">
                {areFieldsDisabled
                  ? t("articles.allArticles.view.title")
                  : t("articles.allArticles.edit.title")}
              </h2>
            </div>
            <div className="create-preview">
              <form
                className=""
                autoComplete="off"
                onSubmit={handleSubmit((d) => handleSubmitCheck(d))}
              >
                <div className="main-wrapper">
                  <h3>{t("articles.allArticles.create.form.title")}</h3>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={lngDirection === "rtl" ? heLocale : enLocale}
                  >
                    <Grid container className={`create-grid ${areFieldsDisabled ? 'view' : 'edit'}`} justifyContent="space-between">
                      <Grid item xs={12}>
                        <Grid container direction="column">
                          <Grid item className="grid-row" xs={12} ref={headerRef}>
                            <Controller
                              control={control}
                              name="header"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <TextField
                                    id="header"
                                    label={t("common.fieldHeaders.header")}
                                    value={value}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      onChange(e);
                                      setHeader(value);
                                      setValue('header', value);
                                    }}
                                    disabled={areFieldsDisabled}
                                  />
                                  {!areFieldsDisabled && (
                                    <p className="field-error">
                                      {errors?.header?.message ?? ""}&nbsp;
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </Grid>
                          <Grid item className="grid-row" xs={12} ref={categoryRef}>
                            <FormControl>
                              <InputLabel
                                id="category-label"
                                disabled={areFieldsDisabled}
                              >
                                {t("common.fieldHeaders.category")}
                              </InputLabel>
                              <Controller
                                control={control}
                                name="category_id"
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <Select
                                      labelId="category-label"
                                      id="category"
                                      displayEmpty
                                      value={value}
                                      defaultValue=""
                                      onChange={(e) => {
                                        const textValue =
                                          // @ts-ignore
                                          e.nativeEvent?.target?.innerText ?? "";
                                        setSelectedCategory(textValue);
                                        onChange(e);
                                      }}
                                      disabled={
                                        areFieldsDisabled || !categories.length
                                      }
                                    >
                                      {categories.map((cat, index) => (
                                        <MenuItem key={index} value={cat.id}>
                                          {cat.contentName}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {!areFieldsDisabled && (
                                      <>
                                        {categories.length ? (
                                          <p className="field-error">
                                            {errors?.category_id?.message ?? ""}
                                            &nbsp;
                                          </p>
                                        ) : (
                                          <p className="field-error">
                                            {t(
                                              "errorMessages.articles.create.noCategories"
                                            )}
                                          </p>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              />
                            </FormControl>
                          </Grid>
                          {template !== 3 && !areFieldsDisabled ? (
                            <Grid item className="grid-row" xs={12} ref={imageRef}>
                              <UploadFile
                                text={t("common.fieldHeaders.uploadImage")}
                                handleFileUpload={handleFileUpload}
                                showImage={false}
                                image={fileUpload.url}
                                enableVideo
                                isMobile
                              />
                              <span className="information-message">{t("informationMessages.formatsAll")}</span>
                              {!areFieldsDisabled && (
                                <p className="field-error">
                                  {!showImage ? errors?.image?.message ?? "" : ""}
                                  &nbsp;
                                </p>
                              )}
                            </Grid>
                          ) : (
                            <></>
                          )}
                          <Grid item className="grid-row" xs={12} ref={appearsRef}>
                            <FormControl>
                              <Controller
                                control={control}
                                name="appears"
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <label
                                      className={`field-label ${areFieldsDisabled ? "text-disabled" : ""
                                        }`}
                                    >
                                      {t("common.fieldHeaders.appears")}
                                    </label>
                                    {roleType !== UserTypeEnum.owner && <><br />
                                        <label className={`field-label ${areFieldsDisabled ? "text-disabled" : ""
                                        }`}>{t('common.fieldHeaders.department')}</label></>}
                                    <MultiSelect
                                      showSelectAll
                                      value={roleType === UserTypeEnum.owner ? value : value.filter(depID => departments.some(dep => dep.id === depID))}
                                      hasError={!!errors?.appears?.message}
                                      isSelectAllSelected={isSelectAllSelected}
                                      mapBy="id"
                                      renderedName="name"
                                      onChange={onDepartmentSelectChange}
                                      selectOptions={roleType === UserTypeEnum.owner ? groups : departments}
                                      isDisabled={
                                        areFieldsDisabled ||
                                        (roleType === UserTypeEnum.owner &&
                                          !groups.length) ||
                                        (roleType !== UserTypeEnum.owner &&
                                          !departments.length)
                                      }
                                    />
                                    {!areFieldsDisabled && (
                                      <>
                                        {(roleType === UserTypeEnum.owner &&
                                          groups.length) ||
                                          (roleType !== UserTypeEnum.owner &&
                                            departments.length) ? (
                                          <>
                                            <p className="field-error">
                                              {" "}
                                              {/* @ts-ignore */}
                                              {errors?.appears?.message ??
                                                ""}&nbsp;{" "}
                                            </p>
                                          </>
                                        ) : (
                                          <p className="field-error">
                                            {roleType === UserTypeEnum.owner
                                              ? t(
                                                "errorMessages.articles.create.noGroups"
                                              )
                                              : t(
                                                "errorMessages.articles.create.noDepartments"
                                              )}
                                          </p>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              />
                            </FormControl>
                          </Grid>
                          {roleType !== UserTypeEnum.owner && secondaryDepartments.length > 0 && <Grid item className="grid-row" xs={12}>
                            <FormControl>
                              <Controller
                                control={control}
                                name="appears"
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <label className={`field-label ${areFieldsDisabled ? "text-disabled" : ""
                                        }`}>{t('common.fieldHeaders.department')}</label>
                                    <MultiSelect
                                      showSelectAll
                                      value={value.filter(depID => secondaryDepartments.some(dep => dep.id === depID))}
                                      isSelectAllSelected={isSelectAllSelectedSecondary}
                                      mapBy="id"
                                      renderedName="name"
                                      onChange={onSecondaryDepartmentSelectChange}
                                      selectOptions={secondaryDepartments}
                                      isDisabled={
                                        areFieldsDisabled ||
                                        (roleType !== UserTypeEnum.owner &&
                                          !secondaryDepartments.length)
                                      }
                                    />
                                      </>
                                    )}
                              />
                            </FormControl>
                          </Grid>}
                          <Grid item className="grid-row" xs={12} ref={pushTextRef}>
                            <FormControlLabel
                              control={
                                <Controller
                                  control={control}
                                  name="is_push_notification"
                                  render={({ field: { onChange, value } }) => (
                                    <Checkbox
                                      color="primary"
                                      checked={value || false}
                                      onChange={onChange}
                                      onClick={() => {
                                        setIsPushNotification(!value);
                                        if (value) {
                                          setValue("push_notification_text", "");
                                        }
                                      }}
                                      disabled={areFieldsDisabled}
                                    />
                                  )}
                                />
                              }
                              label={t("common.fieldHeaders.sendPushNotification")}
                              onClick={() =>
                                getValues("push_notification_text")
                                  ? setValue("push_notification_text", "")
                                  : null
                              }
                              disabled={areFieldsDisabled}
                            />
                            <Controller
                              control={control}
                              name="push_notification_text"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <TextField
                                    id="pushNotificationText"
                                    type="text"
                                    label={t(
                                      "common.fieldHeaders.pushNotificationText"
                                    )}
                                    disabled={
                                      areFieldsDisabled ||
                                      (!areFieldsDisabled && !isPushNotification)
                                    }
                                    value={value}
                                    onChange={onChange}
                                  />
                                  {!areFieldsDisabled && (
                                    <p className="field-error">
                                      {errors?.push_notification_text?.message ??
                                        ""}
                                      &nbsp;
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </Grid>
                          <Grid item className="grid-row">
                            <p
                              className={`${areFieldsDisabled ? "text-disabled" : ""
                                }`}
                            >
                              {t("common.fieldHeaders.whenToPublishArticle")}
                            </p>
                            <Grid container justifyContent="space-between" ref={publishDateRef}>
                              <Grid item xs={12}>
                                <KeyboardDatePicker
                                  // {... !areFieldsDisabled && isPublishDateOpen ? { disablePast: true } : {}}
                                  minDateMessage={t('errorMessages.minimumDate')}
                                  disableToolbar
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="publishDate-picker-inline"
                                  className="date-picker-inline"
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  onChange={(value: any) => {
                                    changeDateOnly(
                                      value,
                                      publishHourAndMinutes,
                                      setPublishDateAndTime,
                                      setPublishInvalidDate,
                                    );
                                  }}
                                  value={convertToDate(publishDateAndTime)}
                                  autoOk={true}
                                  disabled={areFieldsDisabled}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <KeyboardTimePicker
                                  margin="normal"
                                  id="time-picker"
                                  okLabel={t("timePicker.confirm")}
                                  cancelLabel={t("timePicker.cancel")}
                                  value={publishDateAndTime}
                                  onChange={(value: any) => {
                                    changeTimeOnly(
                                      value,
                                      setPublishDateAndTime,
                                      setPublishHourAndMinutes,
                                      setPublishInvalidDate
                                    );
                                  }}
                                  KeyboardButtonProps={{
                                    "aria-label": "Hour",
                                  }}
                                  disabled={areFieldsDisabled}
                                />
                              </Grid>
                              {!areFieldsDisabled ? (
                                <p className="field-error">
                                  {publishInvalidDate ? t("push.errors.time") : ""}
                                  &nbsp;
                                </p>
                              ) : null}
                            </Grid>
                          </Grid>
                          <Grid item className="grid-row">
                            <p
                              className={`${areFieldsDisabled ? "text-disabled" : ""
                                }`}
                            >
                              {t("common.fieldHeaders.whenToArchiveArticle")}
                            </p>
                            <Grid container justifyContent="space-between" ref={archiveDateRef}>
                              <Grid item xs={12}>
                                <KeyboardDatePicker
                                  // {... !areFieldsDisabled && isArchiveDateOpen ? { minDate: publishDateAndTime } : {}}
                                  minDateMessage={t('errorMessages.minimumDate')}
                                  disableToolbar
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="archiveDate-picker-inline"
                                  className="date-picker-inline"
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  onChange={(value: any) => {
                                    changeDateOnly(
                                      value,
                                      archiveHourAndMinutes,
                                      setArchiveDateAndTime,
                                      setArchiveInvalidDate,
                                      "archive"
                                    );
                                  }}
                                  value={convertToDate(archiveDateAndTime)}
                                  autoOk={true}
                                  disabled={areFieldsDisabled}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <KeyboardTimePicker
                                  margin="normal"
                                  id="time-picker"
                                  value={archiveDateAndTime}
                                  okLabel={t("timePicker.confirm")}
                                  cancelLabel={t("timePicker.cancel")}
                                  onChange={(value: any) => {
                                    changeTimeOnly(
                                      value,
                                      setArchiveDateAndTime,
                                      setArchiveHourAndMinutes,
                                      setArchiveInvalidDate,
                                      "archive"
                                    );
                                  }}
                                  KeyboardButtonProps={{
                                    "aria-label": "Hour",
                                  }}
                                  disabled={areFieldsDisabled}
                                />
                              </Grid>
                              {!areFieldsDisabled ? (
                                <p className="field-error">
                                  {archiveInvalidDate ? t("push.errors.time") : ""}
                                  &nbsp;
                                </p>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        className="preview-container flex-center flex-column"
                      >
                        <div className={"preview-image-wrap"} style={{ height: previewImageHeight }}>
                          <div
                            className={`preview-image template-${template}`}
                            style={{
                              backgroundImage:
                                fileUpload?.url && template !== 3 && !isNewPictureUploaded
                                  ? "url(" + fileUpload?.url + ")"
                                  : " ",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }}
                          >
                            {!areFieldsDisabled && isNewPictureUploaded ? (
                              <div className="crop-container">
                                <Cropper
                                  image={
                                    fileUpload?.url && template !== 3
                                      ? fileUpload?.url
                                      : " "
                                  }
                                  crop={crop}
                                  zoom={zoom}
                                  zoomSpeed={0.1}
                                  minZoom={0.05}
                                  maxZoom={maxZoom}
                                  restrictPosition={false}
                                  objectFit={
                                    template !== 2
                                      ? "vertical-cover"
                                      : "horizontal-cover"
                                  }
                                  cropSize={{
                                    width: previewImageWidth,
                                    height: previewImageHeight,
                                  }}
                                  onCropChange={setCrop}
                                  onZoomChange={setZoom}
                                  onCropComplete={onCropComplete}
                                />
                              </div>
                            ) : null}
                            {(template === 1) && (
                              <div className="template-1-container">
                                <div className="preview-category">
                                  <span>{selectedCategory || "Category"}</span>
                                </div>
                                <p className="preview-header articles">
                                  {header ||
                                    t(
                                      "articles.allArticles.create.form.preview.headerGoes"
                                    )}
                                </p>
                                {
                                  shortDescription && (
                                    <p>
                                      {shortDescription}
                                    </p>
                                  )
                                }
                                <p className="preview-date">
                                  {convertToDate(
                                    getValues("publish_date"),
                                    "preview"
                                  )}
                                </p>
                              </div>
                            )}
                          </div>
                          {(template === 2) && (
                            <>
                              <div className="template-2-container">
                                <div className="preview-short-text">
                                  <div className="break-text">
                                    <div className="preview-text-header">
                                      <span>
                                        {header ||
                                          t(
                                            "articles.allArticles.create.form.preview.detailsHeader"
                                          )}
                                      </span>
                                    </div>
                                    <div className="preview-html">
                                      {
                                        details === "externalLink" ? (
                                          <p>
                                            {shortDescription ? shortDescription : t("common.fieldHeaders.shortDescription")}
                                          </p>
                                        ) : (textEditor
                                          ? displayHTML(textEditor)
                                          : t(
                                            "articles.allArticles.create.form.preview.detailsText"
                                          ))
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={`preview-dates`}>
                                <span>{convertToDate(getValues("publish_date"), "preview")}</span>
                              </div>
                            </>
                          )}
                          {
                            template === 3 && (
                              <>
                                <div className="template-3-container">
                                  <div className="preview-category">
                                    <span>{selectedCategory || "Category"}</span>
                                  </div>
                                  <p className="preview-date">
                                    {convertToDate(
                                      getValues("publish_date"),
                                      "preview"
                                    )}
                                  </p>
                                  <div className="preview-short-text">
                                    <div className="break-text">
                                      <div className="preview-text-header">
                                        <span>
                                          {header ||
                                            t(
                                              "articles.allArticles.create.form.preview.detailsHeader"
                                            )}
                                        </span>
                                      </div>
                                      <div className="preview-html">
                                        {
                                          details === "externalLink" ? (
                                            <p>
                                              {shortDescription ? shortDescription : t("common.fieldHeaders.shortDescription")}
                                            </p>
                                          ) : (textEditor
                                            ? displayHTML(textEditor, 250, 8)
                                            : t(
                                              "articles.allArticles.create.form.preview.detailsText"
                                            ))
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`preview-author`}>
                                  <span>{getValues("writer")}</span>
                                </div>
                              </>
                            )
                          }
                          <div className={`preview-icons-mobile template-${template}`}>
                            <FavoriteIcon className="icon favorite" />
                            <MoreHorizIcon className="icon dots" />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </div>
                <div className="main-wrapper">
                  <h3>{t("articles.allArticles.create.form.subTitle")}</h3>
                  <Grid container className={`create-grid ${areFieldsDisabled ? 'view' : 'edit'}`}>
                    <Grid item xs={12}>
                      <Grid container direction="column">
                        <Grid item className="grid-row" xs={12} ref={externalRef}>
                          <RadioGroup name="details" value={details}>
                            <FormControlLabel
                              control={
                                <Radio
                                  color="primary"
                                  onChange={(e) => {
                                    if (areFieldsDisabled) return;
                                    setDetails(e.target.value);
                                    setValue("details", "write");
                                    setValue("external_link", "");
                                    setValue("link_description", "");
                                    setShortDescription("");
                                  }}
                                />
                              }
                              value="write"
                              label={t("common.fieldHeaders.iWillWrite")}
                              disabled={areFieldsDisabled}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  color="primary"
                                  onChange={(e) => {
                                    if (areFieldsDisabled) return;
                                    setDetails(e.target.value);
                                    setValue("details", "externalLink");
                                    setValue("details_header", "");
                                    setValue("writer", "");
                                    setValue("text", "");
                                    setTextEditor("");
                                  }}
                                />
                              }
                              value="externalLink"
                              label={t("common.fieldHeaders.externalLink")}
                              disabled={areFieldsDisabled}
                            />
                          </RadioGroup>
                          {details === "externalLink" && (
                            <>
                              <Controller
                                control={control}
                                name="external_link"
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <TextField
                                      id="external_link"
                                      type="text"
                                      label={t("common.fieldHeaders.url")}
                                      value={value}
                                      onChange={onChange}
                                      disabled={areFieldsDisabled}
                                    />
                                    <p className="field-error">
                                      {details === "externalLink"
                                        ? errors?.external_link?.message ?? ""
                                        : ""}
                                      &nbsp;
                                    </p>
                                  </>
                                )}
                              />
                              <Controller
                                control={control}
                                name="link_description"
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <TextField
                                      id="link_description"
                                      type="text"
                                      label={t("common.fieldHeaders.shortDescription")}
                                      value={value}
                                      onChange={onChange}
                                      disabled={areFieldsDisabled}
                                    />
                                    <p className="field-error">
                                      {details === "externalLink"
                                        ? errors?.link_description?.message ?? ""
                                        : ""}
                                      &nbsp;
                                    </p>
                                  </>
                                )}
                              />
                            </>
                          )}
                        </Grid>
                        <Grid
                          item
                          className="grid-row"
                          xs={12}
                          ref={detailsHeaderRef}
                        >
                          <Controller
                            control={control}
                            name="header"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <TextField
                                  id="header"
                                  type="text"
                                  label={t("common.fieldHeaders.header")}
                                  value={value}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    onChange(e);
                                    setHeader(value);
                                    setValue('header', value);
                                  }}
                                  disabled={
                                    details === "externalLink" || areFieldsDisabled
                                  }
                                />
                                {!areFieldsDisabled && (
                                  <p className="field-error">
                                    {details === "write"
                                      ? errors?.header?.message ?? ""
                                      : ""}
                                    &nbsp;
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item className="grid-row" xs={12} ref={writerRef}>
                          <Controller
                            control={control}
                            name="writer"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <TextField
                                  id="writer"
                                  type="text"
                                  label={t("common.fieldHeaders.writer")}
                                  value={value}
                                  onChange={onChange}
                                  disabled={
                                    details === "externalLink" || areFieldsDisabled
                                  }
                                />
                                {!areFieldsDisabled && (
                                  <p className="field-error">
                                    {details === "write"
                                      ? errors?.writer?.message ?? ""
                                      : ""}
                                    &nbsp;
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item className="grid-row" xs={12} ref={textRef}>
                          <p
                            className={`${details === "externalLink" || areFieldsDisabled
                              ? "text-disabled"
                              : ""
                              }`}
                          >
                            {t("common.fieldHeaders.articleText")}
                          </p>
                          <Controller
                            control={control}
                            name="text"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <Editor
                                  apiKey='7c1lea82c6ixszj0qqq2wtdp01kgm8syyi4eq3r85hprx9mh'
                                  value={textEditor}
                                  init={{
                                    directionality: textInputDirection(),
                                    height: 500,
                                    width: "100%",
                                    ...tinyEditorOptions,
                                    toolbar_mode: 'sliding',
                                    content_style: `body > * { ${areFieldsDisabled ? "color: #8f9bb3;" : ""
                                      } ${lngDirection === "rtl"
                                        ? "text-align: right;"
                                        : "text-align: left;"
                                      }
                                      } body { overflow-x: hidden; }`,
                                  }}
                                  onEditorChange={(value) => {
                                    setTextEditor(value);
                                    onChange(transformEditorText(value));
                                  }}
                                  disabled={
                                    details === "externalLink" || areFieldsDisabled
                                  }
                                />
                                {!areFieldsDisabled && (
                                  <p className="field-error">
                                    {details === "write"
                                      ? errors?.text?.message ?? ""
                                      : ""}
                                    &nbsp;
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <FormHelperText error className="error-text">
                        {submitError
                          ? submitError?.charAt(0).toUpperCase() +
                          submitError?.slice(1)
                          : ""}
                        &nbsp;
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </div>
                <div className="submit-buttons d-flex justify-center">
                  <Button
                    variant="contained"
                    name="PreviewAndPublish"
                    color="primary"
                    type="submit"
                    onClick={() => setTimeout(() => scrollToError())}
                    disabled={
                      !areFieldsDisabled &&
                      !(
                        categories.length &&
                        (groups.length || departments.length) &&
                        roleType
                      )
                    }
                  >
                    {areFieldsDisabled
                      ? t("common.buttons.preview").toUpperCase()
                      : t("common.buttons.save").toUpperCase()}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <>
            <div className="preview-and-publish">
              <div
                className="image-container"
                style={{ height: previewAndPublishImageHeight }}
              >
                {
                  template !== 3 ? (
                    <img
                      className="article-image"
                      src={fileUpload?.url}
                      alt={fileUpload.name}
                    />
                  ) : (
                    <div className={`article-image template-3`} />
                  )
                }
              </div>
              <div className="text-container">
                <div className="category">
                  <span>{selectedCategory}</span>
                </div>
                <div className="header">
                  <span>{header}</span>
                </div>
                {
                  shortDescription && (
                    <p>
                      {shortDescription}
                    </p>
                  )
                }
                <div className="writer-and-date d-flex">
                  <div className="writer">
                    <span>{getValues('writer')}</span>
                  </div>
                  <div className="line">|</div>
                  <div className="date">
                    <span>
                      {convertToDate(
                        getValues("publish_date"),
                        "preview"
                      )}
                    </span>
                  </div>
                </div>
                <div className="content">
                  <span>
                    {textEditor
                      ? displayHTMLPreview(textEditor)
                      : t(
                        "articles.allArticles.create.form.preview.detailsText"
                      )}
                  </span>
                </div>
              </div>
              <div className="phone-icons d-flex justify-between align-center">
                <div className="icon-container">
                  <IconComponent icon={BackIcon} />
                </div>
                <div className="icon-container">
                  <FavoriteBorderOutlinedIcon className="icon favorite" />
                  <BookmarkBorderOutlinedIcon className="icon favorite" />
                  <PublishOutlinedIcon className="icon favorite" />
                  <FormatSizeOutlinedIcon className="icon favorite" />
                </div>
              </div>
            </div>
            <div className="submit-buttons d-flex justify-center">
              <Button
                variant="outlined"
                color="primary"
                className="link-button-container"
                onClick={handleCloseModal}
              >
                {t('common.buttons.close').toUpperCase()}
              </Button>
            </div>
          </>
        )
      }
      <NotificationModal
        openModal={openNotificationModal}
        buttonText={t("common.buttons.close")}
        handleClose={handleNotificationClose}
        handleButtonClick={handleNotificationClose}
        message={notificationMessage}
      />
      {innerLoading ? <Spinner text={t("common.uploading")} /> : null}
    </div>
  );
};

export default ViewArticle;
